import { AssetInterface } from '@/interfaces/asset.interface';

export enum MARKINGS_ORIENTATION {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

export interface ListElementTopMarkingsProps {
    asset: AssetInterface;
    grantExpression?: string;
    width?: number;
}

export interface ListElementBottomMarkingsProps {
    marking: string;
}

export interface ListElementTopMarkingsWrapperProps {
    orientation?: MARKINGS_ORIENTATION;
}

export interface ListElementBottomMarkingsWrapperProps {
    isSafari?: boolean;
}
