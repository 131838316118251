import React from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { useBuildVodData } from '@/hooks/media/use-build-vod-data.hook';
import { ProcessingLoader } from '@/components/shared/Loader';
import { useRouter } from '@/routing';
import { CPID } from '@/types/media.type';
import useGetTvChannelProgramItem from '@/api/navigation/use-get-tv-channel-program-item';
import { FutureEPGDetails } from '@/components/views/FutureEPGDetails';
import { MediaDetailsPageProps } from '@/interfaces/media-details.interface';

const Epg = () => {
    const { query } = useRouter();
    const tvChannelProgramItemId = query.epgId;

    const {
        data,
        error,
        isFetching: isTvProgramItemFetching,
    } = useGetTvChannelProgramItem({
        tvChannelProgramItemId,
    });

    const mediaId = data?.channelIds?.length || error ? data.channelIds[0]?.id : undefined;

    const MediaContent = () => {
        const props = { redirect: !mediaId, mediaId } as MediaDetailsPageProps;
        const { media, isFetching, fmc } = useBuildVodData(
            props,
            CPID.EVENT_OR_CHANNEL,
            false,
            true,
        );
        const singleFmc = fmc.find((f) => f.mediaId.id === mediaId);
        if (isFetching) {
            return <ProcessingLoader isFetching />;
        }

        return <FutureEPGDetails media={media} fmc={singleFmc} />;
    };

    return (
        <BaseLayout fullscreen>
            {isTvProgramItemFetching && <ProcessingLoader isFetching />}
            {!isTvProgramItemFetching && <MediaContent />}
        </BaseLayout>
    );
};

export default Epg;
