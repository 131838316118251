import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { ChannelModel } from '@/models/channels/channel.model';

export const channelToNavId = (channel: ChannelModel) => channel?.getId();

export const epgPositionToNavId = (program: ChannelProgramTvModel) => program?.getId();

export const isNowPlaying = (
    program: ChannelProgramTvModel,
    index: number,
    currentTimestamp: number,
    isTimeshiftingSupported: boolean,
) => (isTimeshiftingSupported ? program.isTimeIn(currentTimestamp) : index === 0);

export const isPlayable = (
    program: ChannelProgramTvModel,
    timeshiftingDuration?: number,
    isCatchUp?: boolean,
) => {
    const dateNow = new Date().getTime();
    const startTimestamp = program.getStartTimeTimestamp();
    const isOnAir = program.getIsOnAir();

    if (isCatchUp) return true;

    return timeshiftingDuration
        ? (startTimestamp > dateNow - timeshiftingDuration * 1000 && startTimestamp < dateNow) ||
              isOnAir
        : false;
};
