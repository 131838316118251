import React, { useMemo } from 'react';
import { ListElementTopMarkingsWrapper } from '@/components/browser/shared/ListElement/Markings/style';
import {
    ListElementTopMarkingsProps,
    MARKINGS_ORIENTATION,
} from '@/components/browser/shared/ListElement/Markings/types';
import { useMarkingsIcons } from '@/hooks/use-markings-icon';
import { Icon } from '@/components/shared/Icon';

const ListElementTopMarkings = ({ asset, grantExpression, width }: ListElementTopMarkingsProps) => {
    const iconsName = useMarkingsIcons(asset, grantExpression);

    const orientation = useMemo(
        () =>
            !width || width >= 135
                ? MARKINGS_ORIENTATION.HORIZONTAL
                : MARKINGS_ORIENTATION.VERTICAL,
        [width],
    );

    const getIcons = () => {
        return iconsName.map((iconName, index) => <Icon name={iconName} key={index} />);
    };

    return (
        <ListElementTopMarkingsWrapper orientation={orientation}>
            {getIcons()}
        </ListElementTopMarkingsWrapper>
    );
};

export const ListElementTopMarkingsMemo = React.memo(ListElementTopMarkings);
