import { formatDate } from '@/helpers/date.helper';
import { gmStatusToAccessStatus } from '@/helpers/product-access.helper';
import { CheckProductAccessOut } from '@/interfaces/from-schemas/drm/checkProductAccessOut';
import { CheckProductsAccessOut } from '@/interfaces/from-schemas/drm/checkProductsAccessOut';
import { ACCESS_STATUS } from '@/interfaces/product-access.interface';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { CallToActionModel } from './call-to-action-model';
import { SpecialAccessModel } from './special-access.model';
export class CheckProductAccessModel {
    private status: ACCESS_STATUS;
    private statusDescription: string;
    private statusUserMessage: string;
    private externalActivationType?: string;
    private specialAccess?: SpecialAccessModel;
    private id?: string;
    private validTo?: string;

    constructor(
        accessDefinition: CheckProductAccessOut,
        productDefinition?: CheckProductsAccessOut[number]['product'],
    ) {
        this.status = gmStatusToAccessStatus(accessDefinition.status);
        this.statusDescription = accessDefinition.statusDescription;
        this.externalActivationType = accessDefinition.externalActivationType;
        this.statusUserMessage = accessDefinition.statusUserMessage || '';
        this.validTo = accessDefinition.validTo;
        if (accessDefinition.specialAccess) {
            this.specialAccess = new SpecialAccessModel(accessDefinition.specialAccess);
        }
        if (productDefinition) {
            this.id = productDefinition.id;
        }
    }

    public getId(): string | undefined {
        return this.id;
    }

    public hasAccess(): boolean {
        return this.status === ACCESS_STATUS.HAS_ACCESS;
    }

    public getStatusDescription(): string {
        return this.statusDescription;
    }

    public getStatusUserMessage(): string {
        return this.statusUserMessage;
    }

    public hasDisneyActivation(): boolean {
        return this.externalActivationType === 'disney';
    }

    public getValidTo(format?: FORMAT_DATE_MODE): string | undefined {
        return format && this.validTo ? formatDate(this.validTo, format) : this.validTo;
    }
    public isSpecialAccess(): boolean {
        return this.specialAccess ? true : false;
    }
    public getCallToActions(): CallToActionModel[] | undefined {
        return this.specialAccess?.getCallToActions();
    }

    public getCallToActionsDescriptions(): string | undefined {
        return this.specialAccess?.getCallToActionsDescriptions();
    }

    public isPlusAction(): boolean | undefined {
        return this.specialAccess?.isPlusAction();
    }
    public isAskBeforePlayAction(): boolean | undefined {
        return this.specialAccess?.isAskBeforePlayAction();
    }
    public getActionButtonText(): string {
        return this.specialAccess?.getActionButtonText() || '';
    }
    public getHelpText(): string {
        return this.specialAccess?.getHelpText() || '';
    }
    public isHelpText(): boolean {
        return this.specialAccess?.isHelpText() || false;
    }
    public getPlaybackConfirmationMessage(): string | undefined {
        return this.specialAccess?.getPlaybackConfirmationMessage();
    }
    public isDeniedStatus(): boolean {
        return this.specialAccess?.isDeniedStatus() || false;
    }
    public isSpecialAccessStatus(): boolean {
        return !!this.specialAccess?.getStatus() || false;
    }
}
