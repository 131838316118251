import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { formatTime } from '@/components/shared/EPGTable/helpers/epg-table.helpers';
import { ICONS } from '@/components/shared/Icon/types';
import TruncatedText from '@/components/shared/MainText/TruncatedText';
import { IconGroup } from '@/components/shared/Metadata';
import { isPlayable } from '@/components/shared/Player/controls/EPG/helpers';
import { useFocusedEPGContext } from '@/contexts/epg-table/focused-epg.context';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { useTablePageContext } from '@/contexts/epg-table/table-page.context';
import { useCellHeight } from '@/hooks/epg-table/use-cell-height.hook';
import t from '@/lib/i18n';

import * as Styled from './styles';
import { SingleCellPresentationProps, SingleCellProps } from './types';
import useGetCatchUpEpg from '@/api/navigation/channels/use-get-catch-up-epg';
import { redirectToWatchUrl } from '@/helpers/watch.helpers';
import { ROUTES } from '@/routing/types';

const SingleCellRaw = ({
    cellsInCell,
    channelName,
    onGoToWatch,
    height,
    program,
    timeShiftingDuration,
    increaseTitleLines = false,
    hasAccess,
}: SingleCellProps) => {
    const { isDueToPageChange } = useTablePageContext();
    const { isVeryLastElementFullyVisible } = useIncrementColumnContext();
    const { setFocusedEPG } = useFocusedEPGContext();
    const [active, setActive] = useState(false);

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleNav = useCallback(
        (isActive: boolean) => {
            const hasProgram = Boolean(program);
            const canSetInactive = !isDueToPageChange || isVeryLastElementFullyVisible;

            if (isActive) {
                if (hasProgram) {
                    setFocusedEPG({ program, channelName });
                }

                if (isMounted.current) {
                    setActive(true);
                }
            } else if (!isActive && canSetInactive) {
                if (isMounted.current) {
                    setActive(false);
                }
            }
        },
        [program, isDueToPageChange, isVeryLastElementFullyVisible],
    );

    if (!program) {
        return (
            <Styled.Cell
                $hasPassed={false}
                $isActive={active}
                $isOneOfManyCurrentlyPlaying={false}
                $isPlayable={false}
                height={height}
                onNav={handleNav}
            />
        );
    }

    return (
        <SingleCellPresentation
            active={active}
            cellsInCell={cellsInCell}
            onGoToWatch={onGoToWatch}
            handleNav={handleNav}
            height={height}
            program={program}
            timeShiftingDuration={timeShiftingDuration}
            increaseTitleLines={increaseTitleLines}
            hasAccess={hasAccess}
        />
    );
};

const SingleCellPresentationRaw = ({
    active,
    cellsInCell = 0,
    onGoToWatch,
    handleNav,
    height,
    program,
    timeShiftingDuration,
    increaseTitleLines,
    hasAccess,
}: SingleCellPresentationProps) => {
    const cellHeight = useCellHeight(height, program);
    const endTimeId = `${formatTime(program.getEndTime())}`;
    const hasPassed = program.getHasPassed();
    const isLive = program.getIsLive();
    const isOnAir = program.getIsOnAir();
    const isOneOfManyCurrentlyPlaying = cellsInCell >= 3 && isOnAir;
    const { data: catchUpData, isFetching } = useGetCatchUpEpg(program.getId());
    const playable = isPlayable(program, timeShiftingDuration, !!catchUpData);

    const progressPercentage = useMemo(() => program.getProgress() * 100, [program]);
    const startTimeId = `${formatTime(program.getStartTime())}`;
    const title = program.getTitle();
    const titleLines = increaseTitleLines ? 4 : 1;

    const handleOnEnterDown = useCallback(() => {
        const timestamp = program.getStartTimeTimestamp() / 1000;
        if (isFetching) return;

        if (hasAccess && catchUpData) {
            redirectToWatchUrl(
                ROUTES.CATCHUP_WATCH,
                {
                    channelId: program.getChannelId(),
                    catchUpId: catchUpData.id,
                },
                true,
            );
            return;
        }

        if (playable) {
            onGoToWatch({ callback: program.goToChannelDetails.bind(program), timestamp });
            return;
        }

        program.goToChannelDetails(); //* EPG: TODO: Obsłużyć przekierowanie do przyszłego EPG, gdy zostanie wdrożone na smart TV
    }, [onGoToWatch, playable, program, isFetching]);

    return (
        <Styled.Cell
            $hasPassed={hasPassed}
            $isActive={active}
            $isOneOfManyCurrentlyPlaying={isOneOfManyCurrentlyPlaying}
            $isPlayable={playable}
            data-end-time={endTimeId}
            data-start-time={startTimeId}
            height={cellHeight}
            onEnterDown={handleOnEnterDown}
            onNav={handleNav}
        >
            <Styled.CellMetadata>
                {startTimeId}
                {isLive && <Styled.LiveText>{t('is-live')}</Styled.LiveText>}
            </Styled.CellMetadata>

            <Styled.TitleWrapper>
                {(isOnAir || playable) && (
                    <IconGroup icon={ICONS.SIMPLE_PLAY} iconSize={1.6} targetColor noMargin />
                )}
                <TruncatedText text={title} lines={titleLines} />
            </Styled.TitleWrapper>

            {isOnAir && <Styled.ProgressBar progress={progressPercentage} />}
        </Styled.Cell>
    );
};

const SingleCellPresentation = React.memo(SingleCellPresentationRaw);

export const SingleCell = React.memo(SingleCellRaw);
