import { NavFuncInterface } from 'nav-tree';
import * as React from 'react';
import { ThemeProps } from 'styled-components';
import { GUTTER } from '../../../../types/gutter.type';
import { ICONS } from '../../Icon';
import { CyProps } from '@/types/cypress.type';

export interface PlayableButtonProps extends CyProps {
    onButtonAction: () => void;
    onNav?: (isActive: boolean) => void;
    func?: NavFuncInterface;
    defaultFocused?: boolean;
    text?: string;
    navId?: string;
    icon?: ICONS;
    style?: React.CSSProperties;
    gutter?: GUTTER;
    transparentOnInactive?: boolean;
    overlay?: React.ReactNode;
    className?: string;
    variant?: PLAYABLE_VARIANT;
    onlyIcon?: boolean;
    onlyText?: boolean;
    disabled?: boolean;
}

export interface PlayableDynamicButtonProps extends CyProps {
    onButtonAction: () => void;
    isFullyVisible?: boolean;
    defaultFocused?: boolean;
    onNav?: (isActive: boolean) => void;
    navId?: string;
    func?: NavFuncInterface;
    text: string;
    icon: ICONS;
    className?: string;
    iconSize?: number;
    style?: React.CSSProperties;
    enableLongPress?: boolean;
}

export interface IIconButtonProps extends CyProps {
    onButtonAction: () => void;
    onNav?: (isActive: boolean) => void;
    defaultFocused?: boolean;
    navId?: string;
    icon: ICONS;
    size?: 'small' | 'large';
}

export interface ButtonContentProps extends ThemeProps<any> {
    active: boolean;
    isFullyVisible?: boolean;
}

export interface ActiveProps extends ThemeProps<any> {
    active: boolean;
}

export interface StyledButtonContentProps extends ActiveProps {
    onlyIcon?: boolean;
    transparentOnInactive?: boolean;
    variant?: PLAYABLE_VARIANT;
}

export enum PLAYABLE_VARIANT {
    PRIMARY = 'primary',
}

export interface IconButtonNavProps {
    iconSize?: 'small' | 'large';
}
