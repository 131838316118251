import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { StyledText, StyledChild, StyledLoadingWrapper } from './style';
import { LoadingWrapperProps } from './types';
import { Dots } from './Dots';

export const LoadingWrapper = ({
    children,
    isFetching,
    text,
    highlight = false,
    keepChildrenVisible = false,
    calculateBottom = true,
    fixed = false,
}: LoadingWrapperProps) => {
    const { dots, delay } = process.env.loader.wrapper;
    const childrenRef = useRef() as MutableRefObject<HTMLDivElement>;

    const childrenHeight = childrenRef?.current?.clientHeight;

    const [waited, setWaited] = useState<boolean>(false);

    useEffect(() => {
        if (isFetching) {
            setWaited(false);
            const timer = setTimeout(() => {
                setWaited(true);
            }, delay);

            return () => clearTimeout(timer);
        }
    }, [isFetching]);

    const buildLoader = () => (
        <StyledLoadingWrapper
            height={childrenHeight}
            windowsHeight={window.innerHeight}
            calculateBottom={calculateBottom}
            fixed={fixed}
        >
            <Dots dots={dots} />
            {text && <StyledText highlight={highlight}>{text}</StyledText>}
        </StyledLoadingWrapper>
    );

    const isLoaderVisible = waited && isFetching;

    if (keepChildrenVisible) {
        return (
            <>
                <StyledChild
                    keepChildrenVisible
                    isLoaderVisible={isLoaderVisible}
                    ref={childrenRef}
                >
                    {children}
                </StyledChild>
                {isLoaderVisible && buildLoader()}
            </>
        );
    }

    return isLoaderVisible ? buildLoader() : <>{children}</>;
};
