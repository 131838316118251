import styled, { css } from 'styled-components';
import {
    ListElementBottomMarkingsProps,
    ListElementBottomMarkingsWrapperProps,
    ListElementTopMarkingsWrapperProps,
    MARKINGS_ORIENTATION,
} from '@/components/browser/shared/ListElement/Markings/types';

export const ListElementTopMarkingsWrapper = styled.div<ListElementTopMarkingsWrapperProps>`
    display: flex;
    position: absolute;
    gap: 0.1rem;
    z-index: 1;
    top: 0.5rem;
    left: 0.5rem;

    ${({ orientation }) => {
        if (orientation === MARKINGS_ORIENTATION.VERTICAL)
            return css`
                flex-direction: column;
            `;
        return css`
            flex-direction: row;
        `;
    }}
    svg {
        width: 1.8rem;
        height: 1.7rem;
        transition: fill 0.1s linear;
        background: #070708b2;

        :first-child {
            ${({ orientation }) => {
                if (orientation === MARKINGS_ORIENTATION.VERTICAL)
                    return css`
                        border-radius: 0.3rem 0.3rem 0 0;
                    `;
                return css`
                    border-radius: 0.3rem 0 0 0.3rem;
                `;
            }}
        }

        :last-child {
            ${({ orientation }) => {
                if (orientation === MARKINGS_ORIENTATION.VERTICAL)
                    return css`
                        border-radius: 0 0 0.3rem 0.3rem;
                    `;
                return css`
                    border-radius: 0 0.3rem 0.3rem 0;
                `;
            }}
        }

        :only-child {
            border-radius: 0.3rem;
        }
    }
`;
