import styled, { css } from 'styled-components';
import { PlayableDynamicButton } from '../Buttons';
import { Nav } from 'nav-tree';

export const ScrollableContainerWrapper = styled(Nav as any)``;

export const SCROLLABLE_WRAPPER_PADDING_PX = 160;

export const ScrollableContentWrapper = styled.div<{
    withBottomButtons?: boolean;
    fullWidth?: boolean;
}>`
    ${({ fullWidth }) =>
        !fullWidth &&
        css`
            margin-right: 26rem;
        `};

    padding: ${SCROLLABLE_WRAPPER_PADDING_PX / 10}rem 0;
    height: ${({ withBottomButtons }) => (withBottomButtons ? '85vh' : '100vh')};
    font-size: 2.4rem;
    overflow-y: hidden;
`;

export const ScrollableContent = styled.div`
    font-size: 2.4rem;
    position: relative;
    img {
        width: 100%;
    }
`;

export const AbsolutePositionDynamicButton = styled(PlayableDynamicButton)<{
    fixed?: boolean;
}>`
    position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
    right: 5rem;
    z-index: 2;
`;
