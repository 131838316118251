import React, { useContext, useEffect, useRef } from 'react';
import { IPlayerBaseProps } from './types';
import { usePlayer } from '@/hooks/player/use-player';
import { PlayerDevSniffer } from './PlayerDevSniffer';
import { ControlsBase } from './controls/ControlsBase';
import { isServer } from '@/helpers/environment.helper';
import { PlayerWrapper } from './styles';
import PlayerContextProvider from '@/contexts/player/player.context';
import { usePlayerVisibility } from '@/hooks/player/use-player-visibility';
import { VideoContainer } from '@/components/shared/Player/VideoContainer';
import { GuiStateContextProvider } from '@/contexts/player/gui-state.context';
import { PlayerSubtitlesOptionsContextProvider } from '@/contexts/player/subtitles.context';
import { PlayerVolumeContextProvider } from '@/contexts/player/volume.context';
import {
    PlayerLoaderContext,
    PlayerLoaderContextProvider,
} from '@/contexts/player/player-loader.context';
import { useLoadPlayerScript } from '@/hooks/player/use-load-player-script.hook';

export const PlayerBase = (props: IPlayerBaseProps) => {
    return (
        <PlayerLoaderContextProvider>
            <GuiStateContextProvider>
                <PlayerSubtitlesOptionsContextProvider>
                    <PlayerVolumeContextProvider>
                        <PlayerBaseContent {...props} />
                    </PlayerVolumeContextProvider>
                </PlayerSubtitlesOptionsContextProvider>
            </GuiStateContextProvider>
        </PlayerLoaderContextProvider>
    );
};

export const PlayerBaseContent = ({
    load,
    customOptions,
    children,
    mediaDetails,
    renderFallback,
    isWatchingBegin,
    isFmcScreenVisible,
    isFreeAccessVisible,
    isFreeAccessConfirmation,
}: IPlayerBaseProps) => {
    const playerWrapperRef = useRef<HTMLDivElement>(null);
    const playerContainerRef = useRef<HTMLDivElement>(null);
    const [status, timeMarkers, instance] = usePlayer({
        playerContainerRef,
        load,
        customOptions,
        catid: mediaDetails.mainCategoryId,
    });
    const { loadPlayerScript } = useLoadPlayerScript();
    const { playerScriptLoading, playerScriptLoaded } = useContext(PlayerLoaderContext);

    useEffect(() => {
        if (!playerScriptLoading && !playerScriptLoaded) {
            loadPlayerScript();
        }
    }, [loadPlayerScript, playerScriptLoading, playerScriptLoaded]);

    usePlayerVisibility();

    useEffect(() => {
        if (!instance) {
            return;
        }
        if (isFreeAccessConfirmation && !isFreeAccessVisible) {
            instance?.user.play();
        }
        if (isWatchingBegin && !isFmcScreenVisible && status.phase === 'DURING_PLAYBACK') {
            instance?.user.skip(0);
        }
    }, [isWatchingBegin, isFmcScreenVisible, instance, status.phase]);

    return (
        <PlayerWrapper ref={playerWrapperRef}>
            <PlayerContextProvider
                playerWrapper={playerWrapperRef.current}
                instance={instance}
                status={status}
                timeMarkers={timeMarkers}
                mediaDetails={mediaDetails}
            >
                <VideoContainer elementRef={playerContainerRef} />
                {!instance ? (
                    renderFallback?.() || null
                ) : (
                    <>
                        {process.env.NODE_ENV === 'development' && !isServer && (
                            <PlayerDevSniffer />
                        )}
                        {!mediaDetails ? <ControlsBase isFetchingMediaDetails visible /> : children}
                    </>
                )}
            </PlayerContextProvider>
        </PlayerWrapper>
    );
};
