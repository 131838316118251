import { Nav, navVertical } from 'nav-tree';
import { Message } from '../../Message';
import { GUTTER } from '@/types/gutter.type';
import { PlayableButton } from '../../Buttons';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { BackButton } from '../../Buttons/BackButton';
import {
    NavContainer,
    Container,
    ButtonContainer,
    MessageContainer,
    BackButtonWrapper,
} from './styles';
import FullscreenNavContainer from '../../FullscreenNavContainer';

export const FreeAccessPlusZone = () => {
    const Body = () => {
        return (
            <Container>
                <MessageContainer>
                    <Message
                        title={t('free-access.free-access-confirmation.go-to-www', {
                            portal: t('domain-url'),
                        })}
                        gutter={GUTTER.BIG}
                    />
                </MessageContainer>
                <ButtonContainer>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => Router.back()}
                        defaultFocused
                    />
                </ButtonContainer>
            </Container>
        );
    };
    const body = <Body />;

    return (
        /* @ts-ignore */
        <NavContainer func={navVertical}>
            <BackButtonWrapper>
                <BackButton />
            </BackButtonWrapper>
            <FullscreenNavContainer body={body} bodyWidth={100} />
        </NavContainer>
    );
};
