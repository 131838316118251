import styled, { css } from 'styled-components';
import { MetaDataInner } from '@/components/shared/ListElement/Metadata/styles';
import { ProgressBarContainer } from '@/components/shared/ProgressBar/styles';
import { buildMediaQuery } from '@/helpers/media-query.helper';
import { Z_INDEX } from '@/targets/default/theme';
import { responsiveSizes } from '@/targets/default/mediaQuery';
import { ElementWrapperProps, ImageNestedWrapperProps } from './types';
import { ImageStyled, PlaceholderStyled } from '@/components/shared/Image/styles';
import { StyledAnchor } from '@/components/shared/LinkWrapper/styles';
import { ListElementBottomMarkingsWrapperProps } from '@/components/browser/shared/ListElement/Markings/types';

const buildActiveHover = ({ active, hoverDisabled, theme }: ElementWrapperProps) => {
    const { sx, sy } = theme.listElement.activeTransform;

    if (!active || hoverDisabled) return;

    return css`
        @media (hover: hover) {
            &:hover {
                z-index: ${theme.listElement.activeZIndex};

                ${ImageContainer} {
                    transform: scale3d(${sx}, ${sy}, 1);
                }

                ${ListElementBottomMarkingsWrapper} {
                    transform: scale3d(1, 1.03, 1);
                }

                ${ImageNestedWrapper} {
                    box-shadow: ${theme.listElement.activeShadow};
                }

                ${MetaDataInner} {
                    transform: scale3d(calc(1 / ${sx}), calc(1 / ${sy}), 1);
                }
            }
        }
    `;
};

export const ImageContainer = styled.div`
    -webkit-backface-visibility: hidden;
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    transition: transform 0.1s linear;
    z-index: ${Z_INDEX.LIST_ELEMENT};
    position: relative;

    ${StyledAnchor} {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: ${Z_INDEX.LIST_ELEMENT + 1};
    }
`;

export const ElementWrapper = styled.div<ElementWrapperProps>`
    ${({ relative }) =>
        relative &&
        css`
            position: relative;
            ${buildMediaQuery('right', responsiveSizes.globalPadding)};
        `}
    ${ImageStyled} {
        object-fit: cover;
    }

    ${ImageContainer} {
        opacity: ${({ active, theme }) => (active ? 1 : theme.slider.hideOpacity)};
        ${({ clickDisabled }) =>
            clickDisabled &&
            css`
                cursor: auto;
            `}
    }

    ${MetaDataInner} {
        transform-origin: top left;
        transition: transform 0.1s linear;
    }

    ${ProgressBarContainer} {
        transition: opacity 0.1s linear;
    }

    ${(props) => buildActiveHover(props)};
`;

export const ImageNestedWrapper = styled.div<ImageNestedWrapperProps>`
    overflow: hidden;
    position: relative;
    transition: transform 0.1s linear;
    width: 100%;
    border-radius: 0.6rem;
    z-index: 2;
    ${({ displayMode }) =>
        displayMode &&
        css`
            height: 0;
            padding-top: ${({ theme }) => theme.listElement.imageHeight[displayMode]};

            ${ImageStyled}, ${PlaceholderStyled} {
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        `}
    ${({ isActiveEpisode }) =>
        isActiveEpisode &&
        css`
            border: ${({ theme }) => ` 2px solid  ${theme.text.color.highlight}`};
        `};
`;

export const PlaceholderContainer = styled.div`
    position: relative;
    z-index: ${Z_INDEX.LIST_ELEMENT};
    ${buildMediaQuery('right', responsiveSizes.globalPadding)};
`;

export const StyledListElementShowMore = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: black;
    font-size: 1.8rem;
    transition: transform 0.1s linear, color 0.1s linear;
    z-index: ${Z_INDEX.LIST_ELEMENT};
    position: relative;
    text-decoration: none;
    color: ${({ theme }) => theme.text.color.primary};

    :hover {
        color: ${({ theme }) => theme.text.color.highlight};

        svg {
            fill: ${({ theme }) => theme.text.color.highlight};
        }
    }

    svg {
        transition: fill 0.1s linear;
        fill: ${({ theme }) => theme.slider.button.svgFillActive};
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0.5rem;
    }
`;

export const ShowMoreElementWrapper = styled(ElementWrapper)`
    margin-bottom: 2.5rem;

    ${({ theme }) => {
        const { sx, sy } = theme.listElement.activeTransform;
        return css`
            &:hover {
                ${StyledListElementShowMore} {
                    transform: scale3d(${sx}, ${sy}, 1);
                }
            }
        `;
    }}
`;

export const ListElementBottomMarkingsWrapper = styled.div<ListElementBottomMarkingsWrapperProps>`
    font-family: 'Barlow Condensed', serif;
    font-weight: 600;
    font-style: normal;
    background: #ffc700;
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    border-radius: 3px;
    padding: 0.2rem 0.4rem 0.3rem;
    line-height: 1.2rem;
    font-size: 12px;
    color: #000000;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    transition: transform 0.1s linear, color 0.1s linear;

    ${({ isSafari }) => {
        if (isSafari)
            return css`
                font-size: 12.5px;
            `;
    }}
`;
