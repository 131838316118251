import { GUTTER } from '@/types/gutter.type';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const NavContainer = styled(Nav as any)`
    position: relative;
    width: 100%;
    height: 100%;
`;
export const ButtonContainer = styled.form<{ topGutter?: GUTTER }>`
    width: 44rem;
    display: inline-block;
    ${({ topGutter }) => topGutter && `margin-top: ${topGutter}rem`}
`;

export const MessageContainer = styled.div`
    width: 78rem;
`;

export const BackButtonWrapper = styled.div`
    position: relative;
    top: -3rem;
    left: 6rem;
`;
