import React, { useContext, useMemo } from 'react';
import {
    MediaRelatedTileImage,
    MediaRelatedTileStyled,
    MediaRelatedTileContainer,
} from '@/components/shared/MediaRelatedList/styles';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { MediaRelatedTileProps } from '@/components/shared/MediaRelatedList/types';
import { ListElementTopMarkings } from '@/components/browser/shared/ListElement/Markings';
import { ListElementBottomMarkings } from '@/components/shared/ListElement/Markings';
import { getPremiereMarkings } from '@/helpers/get-premiere-markings';

const MediaRelatedTileRaw = ({ media, navId, onNav, onAction }: MediaRelatedTileProps) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const image = useMemo(() => media.getImage(), [media]);
    const imageSize = useMemo(
        () =>
            image.displayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? process.env.imageSize.posters
                : process.env.imageSize.thumbnails,
        [image],
    );
    const grantExpression = media.getGrantExpression();
    const premiereMarking = getPremiereMarkings(media);

    return (
        <MediaRelatedTileStyled
            navId={navId}
            onNav={onNav}
            onEnterDown={onAction}
            onClick={onAction}
            autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
        >
            <MediaRelatedTileContainer width={imageSize.width} height={imageSize.height}>
                <ListElementTopMarkings
                    asset={media.getAsset()}
                    grantExpression={grantExpression}
                />
                <MediaRelatedTileImage src={image.src} />
                {premiereMarking && <ListElementBottomMarkings marking={premiereMarking} />}
            </MediaRelatedTileContainer>
        </MediaRelatedTileStyled>
    );
};

export const MediaRelatedTile = React.memo(MediaRelatedTileRaw);
