import { SPECIAL_ACCESS_STATUS } from '@/interfaces/product-access.interface';
import { CallToActionModel } from './call-to-action-model';

export class SpecialAccessModel {
    private status: SPECIAL_ACCESS_STATUS;
    private actionButtonText: string;
    private helpText: string;
    private playbackOptions: {
        askBeforePlay: boolean;
        playbackConfirmationMessage: string;
        rememberUserChoice: boolean;
    };
    private callToActions?: CallToActionModel[] = [];

    constructor(definition: any) {
        this.status = definition.status;
        this.actionButtonText = definition.actionButtonText;
        this.helpText = definition.helpText;
        this.playbackOptions = definition.playbackOptions;
        this.callToActions = definition.callToActions?.map(
            (callToAction: any) => new CallToActionModel(callToAction),
        );
    }

    public getStatus(): SPECIAL_ACCESS_STATUS {
        return this.status;
    }
    public isDeniedStatus(): boolean {
        return this.status === SPECIAL_ACCESS_STATUS.DENIED;
    }

    public getActionButtonText(): string {
        return this.actionButtonText;
    }
    public getHelpText(): string {
        return this.helpText;
    }
    public isHelpText(): boolean {
        return this.helpText ? true : false;
    }
    public getPlaybackOptions() {
        return this.playbackOptions;
    }
    public getAskBeforePlay(): boolean {
        return this.playbackOptions?.askBeforePlay;
    }
    public getPlaybackConfirmationMessage(): string {
        return this.playbackOptions?.playbackConfirmationMessage;
    }
    public getBeforePlayRememberUserChoice(): boolean {
        return this.playbackOptions?.rememberUserChoice;
    }
    public getCallToActions(): CallToActionModel[] | undefined {
        return this.callToActions;
    }
    public getCallToActionsDescriptions(): string | undefined {
        const callToActions = this.callToActions
            ?.map((el) => el.getDescription())
            .filter((item) => item)
            .join(' ');

        return callToActions;
    }

    public isPlusAction(): boolean {
        return this.callToActions?.some((callToAction) => callToAction.isPlusAction()) || false;
    }
    public isAskBeforePlayAction(): boolean {
        if (this.playbackOptions?.askBeforePlay === true) {
            return true;
        }
        return false;
    }
}
