import React, { useCallback } from 'react';
import { PlayableButton } from '../../shared/Buttons';
import { ButtonContainer, NavButtons } from './styles';
import { IMediaButtons } from './types';
import { StaffCollectionCallToAction } from '../MediaDetails/StaffCollectionCallToAction';
import { NAVIGATION_KEYS, Nav, NavTree, navHorizontal } from 'nav-tree';
import { focusNavId } from '@/helpers/nav-tree/focus-nav-id.helper';
import { BUTTON_ID } from '@/types/button-id.type';

const MediaButtonsRaw = ({
    buttons,
    focusRef,
    onRightPanel,
    onButtonAction,
    staffCollectionsId,
    imgSrc,
    staffCollectioTitle,
    isStaffCollection,
    isDeniedStatus = false,
}: IMediaButtons) => {
    const func = useCallback((navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
        if (navKeys === NAVIGATION_KEYS.DOWN) {
            if (navTree.focusedNode === BUTTON_ID.BACK) {
                focusNavId(navTree, BUTTON_ID.CTA_BTN);
                return;
            }

            focusNavId(navTree, BUTTON_ID.BASKET);
            focusNavId(navTree, BUTTON_ID.PLAY);
            return;
        }
        if (navKeys === NAVIGATION_KEYS.UP) {
            if (navTree.focusedNode === BUTTON_ID.CTA_BTN) {
                focusNavId(navTree, BUTTON_ID.BACK);
                return;
            }
            focusNavId(navTree, BUTTON_ID.CTA_BTN);
            return;
        }

        return navHorizontal(navKeys, navTree);
    }, []);
    return (
        // @ts-ignore
        <Nav func={func} restoreLastFocus>
            <NavButtons ref={focusRef} restoreLastFocus>
                {buttons.map((button, index) => {
                    const isPlayOrBuyBtn =
                        button.id === BUTTON_ID.PLAY ||
                        button.id === BUTTON_ID.BASKET ||
                        button.id === BUTTON_ID.DENIED;
                    return (
                        <ButtonContainer key={button.id}>
                            <PlayableButton
                                disabled={button.id === BUTTON_ID.DENIED}
                                onlyIcon={!isPlayOrBuyBtn}
                                onlyText={isPlayOrBuyBtn}
                                navId={button.id}
                                onNav={onRightPanel}
                                icon={button.icon}
                                text={button.text}
                                onButtonAction={() => onButtonAction(button, index)}
                                defaultFocused={index === 0}
                                data-testing={button['data-testing']}
                            />
                        </ButtonContainer>
                    );
                })}
            </NavButtons>
            {staffCollectionsId && staffCollectioTitle && isStaffCollection && (
                <StaffCollectionCallToAction
                    imgSrc={imgSrc}
                    collectionId={staffCollectionsId}
                    staffCollectioTitle={staffCollectioTitle}
                />
            )}
        </Nav>
    );
};

export const MediaButtons = React.memo(MediaButtonsRaw);
