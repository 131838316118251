import { Nav } from 'nav-tree';
import React, { useRef } from 'react';

import { MediaCategoryProps } from '@/components/browser/shared/MediaDetails/types';
import { AdditionalSigns } from '@/components/shared/AdditionalSigns/AdditionalSigns';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import { FavoritesAlert } from '@/components/shared/FavoritesAlert';
import { SubcategoriesMenu } from '@/components/shared/Filter/SubcategoriesMenu';
import { Icon, ICONS } from '@/components/shared/Icon';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { Metadata } from '@/components/shared/Metadata';
import { METADATA_TYPES } from '@/components/shared/Metadata/types';
import ProgressBar from '@/components/shared/ProgressBar';
import { RatingIconWrapper } from '@/components/shared/RatingIconWrapper/styles';
import { AdultContentAgreement } from '@/components/views/AdultContentAgreement';
import { MediaButtons } from '@/components/views/MediaButtons';
import { getIconName } from '@/helpers/get-icon-name.helper';
import { useAgreeToContent } from '@/hooks/media/use-agree-to-content.hook';
import { useMediaBackground } from '@/hooks/media/use-media-background.hook';
import { useMediaDetailsFavorities } from '@/hooks/media/use-media-details-favorities.hook';
import { useMediaDetailsSubcategories } from '@/hooks/media/use-media-details-subcategories.hook';
import t from '@/lib/i18n';
import { ADDITIONAL_SIGNS_TYPES } from '@/types/additional-signs.type';
import { GUTTER } from '@/types/gutter.type';

import {
    ButtonGroupContainer,
    ContentContainer,
    IconWrapper,
    InfoText,
    InfoTextContainer,
    MediaDetailsContainer,
    MetadataContainer,
    MetadataIconWrapper,
    MetadataWrapper,
    PremiereBtnContainer,
    ProgressBarContainer,
    ProgressBarFmcWrapper,
    ProgressContainer,
} from './styles';
import {
    GALLERY_BACKGROUND_EFFECT,
    GALLERY_IMAGE_ASPECT,
    useGalleryImage,
} from '@/hooks/use-gallery-image';
import { ELEMENT_WIDTH } from './types';
import { formatDate } from '@/helpers/date.helper';
import { FORMAT_DATE_MODE } from '@/types/date.type';

export const MediaCategory = ({ media, cnc, isMainCategory, fmc }: MediaCategoryProps) => {
    const { showAgreeToContentModal, setShowAgreeToContentModal } = useAgreeToContent(
        media.isForAdults,
    );

    const focusRef = useRef<Nav>(null);

    const cncButtons = isMainCategory ? cnc.moreActionButtons : false;

    useMediaBackground(cnc, SHADE_MODE.MEDIA, !showAgreeToContentModal);
    const { showInfo, handleButtonAction } = useMediaDetailsFavorities(media);
    const showProgressBar = fmc?.mediaId.id === media.id;
    const underageClassification = media.underageClassification;
    const accessibilityFeatures = media.accessibilityFeatures;

    const {
        data: subcategoriesData,
        mediaButtons,
        subcategoriesMenuTitle,
        rightPanelOpen,
        setRightPanelOpen,
        handleRightPanel,
    } = useMediaDetailsSubcategories(media, focusRef, cncButtons);
    const metadata = [media.duration, ...(showProgressBar ? [media.followMeContent(fmc)] : [])];
    //mamy wyswietlac 1 element kolekcji, GM zwraca nam najbardziej aktualną
    const isStaffCollection = media.getIsStaffCollections();
    const staffCollectionsId = media.getStaffCollectionId() as number;
    const staffCollectionGallery = media.getStaffCollectionGallery();
    const staffCollectioTitle = media.getStaffCollectionName();

    const config = {
        aspect: GALLERY_IMAGE_ASPECT.WIDESCREEN,
        customParams: {
            backgroundEffect: GALLERY_BACKGROUND_EFFECT.NOT_TRANSPARENT,
        },
    };
    const { image } = useGalleryImage(ELEMENT_WIDTH, staffCollectionGallery, config);

    if (showAgreeToContentModal) {
        return (
            <AdultContentAgreement handleConfirmation={() => setShowAgreeToContentModal(false)} />
        );
    }
    return (
        <MediaDetailsContainer>
            <BackButton />
            <ContentContainer>
                <MainText
                    text={cnc.getAdditionalContent()}
                    mode={MAINTEXT_KIND.METADATA_SEASON_EPISODE_TITLE}
                    gutter={GUTTER.SMALL}
                />
                <MainText text={cnc.getTitle()} mode={MAINTEXT_KIND.TITLE} gutter={GUTTER.SMALL} />

                <MainText
                    text={cnc.getDescription()}
                    mode={MAINTEXT_KIND.DESCRIPTION}
                    lines={media.shortDescriptionLines}
                />

                <MetadataContainer>
                    <MetadataWrapper>
                        <Metadata
                            data={[
                                ...media.subTitleData,
                                ...metadata,
                                underageClassification || media.ageGroup > 0 ? ' ' : '',
                            ]}
                            type={METADATA_TYPES.SECONDARY}
                            isSafeHTML
                        />
                        {showProgressBar && fmc && (
                            <ProgressBarFmcWrapper>
                                <ProgressBar progress={fmc.lastPercent} />
                            </ProgressBarFmcWrapper>
                        )}

                        {media.progress !== null && (
                            <ProgressContainer>
                                <Metadata
                                    data={[media.minutesLeftInfo]}
                                    type={METADATA_TYPES.SECONDARY}
                                />
                                <ProgressBarContainer>
                                    <ProgressBar progress={media.progress} />
                                </ProgressBarContainer>
                            </ProgressContainer>
                        )}
                    </MetadataWrapper>
                    <MetadataIconWrapper>
                        {media.ageGroup > 0 && (
                            <RatingIconWrapper ageGroup={media.ageGroup} $height={2.9}>
                                <Icon name={getIconName(media.ageGroup)} />
                            </RatingIconWrapper>
                        )}

                        <AdditionalSigns
                            type={ADDITIONAL_SIGNS_TYPES.UNDERAGE_CLASSIFICATION}
                            signs={underageClassification}
                            $iconSize={2.9}
                        />
                        <AdditionalSigns
                            type={ADDITIONAL_SIGNS_TYPES.ACCESSIBILITY_FEATURES}
                            signs={accessibilityFeatures}
                            $iconSize={2.9}
                        />
                    </MetadataIconWrapper>
                </MetadataContainer>
                <MetadataContainer>
                    <MetadataWrapper>
                        <Metadata
                            data={media.accessMetadata}
                            type={METADATA_TYPES.TERTIARY}
                            isSafeHTML
                        />
                    </MetadataWrapper>
                </MetadataContainer>

                <ButtonGroupContainer>
                    {media.buyOnWwwInfo && (
                        <InfoTextContainer largeMargin>
                            <IconWrapper>
                                <Icon name={ICONS.INFO_CIRCLE_FILLED} />
                            </IconWrapper>

                            <InfoText highlight>{media.buyOnWwwInfo}</InfoText>
                        </InfoTextContainer>
                    )}
                    {media.isStartLiveInfoVisible && (
                        <InfoTextContainer>
                            <InfoText>{t('start-transmission')}:</InfoText>
                            <InfoText highlight>{media.startLiveInfo}</InfoText>
                        </InfoTextContainer>
                    )}
                    {media.isFutureOfferPublicationDate() && media.isDeniedStatus() && (
                        <PremiereBtnContainer>
                            <InfoText>{t('markings.premiere-today')}:</InfoText>
                            <InfoText highlight>
                                {formatDate(
                                    media.getFutureOfferPublicationDate(),
                                    FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT,
                                )}
                            </InfoText>
                        </PremiereBtnContainer>
                    )}

                    <MediaButtons
                        isDeniedStatus={media.isDeniedStatus()}
                        buttons={mediaButtons}
                        focusRef={focusRef}
                        onRightPanel={handleRightPanel}
                        onButtonAction={handleButtonAction}
                        imgSrc={image.src}
                        staffCollectionsId={staffCollectionsId}
                        staffCollectioTitle={staffCollectioTitle}
                        isStaffCollection={isStaffCollection}
                    />
                </ButtonGroupContainer>
                {/* TODO: realizacja w zadaniu SWN-5417 - nowy telst media.getHelpText() */}
            </ContentContainer>
            <SubcategoriesMenu
                subcategories={subcategoriesData.getResults()}
                name={subcategoriesMenuTitle}
                isVisible={rightPanelOpen}
                onAction={() => setRightPanelOpen(false)}
                onShowMenu={setRightPanelOpen}
                selectedCategoryId={media.categoryId}
            />

            <FavoritesAlert
                showInfo={showInfo}
                favoritesType={media.favoritesType}
                isInFavorites={media.isInFavorites}
            />
        </MediaDetailsContainer>
    );
};
