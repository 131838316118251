import { FORMAT_DATE_MODE } from '@/types/date.type';
import { formatDate, weekdayName } from '../date.helper';
import addDays from 'date-fns/addDays';
import t from '@/lib/i18n';
import subDays from 'date-fns/subDays';

type DayFilter = {
    label: string;
    value: string;
};

const TOTAL_DAYS_TO_SHOW = 7;

export const getToday = () => formatDate(new Date().toISOString(), FORMAT_DATE_MODE.ISO_8601);
export const getEarliestDay = () => getDayFilters()[0].value;
export const getLatestDay = () => getDayFilters()[TOTAL_DAYS_TO_SHOW].value;

export const getDayFilters = (): DayFilter[] => {
    const today = new Date();
    const filters: DayFilter[] = [
        {
            label: t('date.yesterday'),
            value: formatDate(subDays(today, 1).toISOString(), FORMAT_DATE_MODE.ISO_8601),
        },
        {
            label: t('date.today'),
            value: formatDate(today.toISOString(), FORMAT_DATE_MODE.ISO_8601),
        },
        {
            label: t('date.tomorrow'),
            value: formatDate(addDays(today, 1).toISOString(), FORMAT_DATE_MODE.ISO_8601),
        },
    ];

    let nextDate = addDays(today, 2);
    for (let i = 2; i < TOTAL_DAYS_TO_SHOW; i++) {
        filters.push({
            label: `${t(`date.${weekdayName(nextDate.getDay())}`)} ${formatDate(
                nextDate.toISOString(),
                FORMAT_DATE_MODE.DATE,
            )}`,
            value: formatDate(nextDate.toISOString(), FORMAT_DATE_MODE.ISO_8601),
        });
        nextDate = addDays(nextDate, 1);
    }

    return filters;
};

export const getPreviousDayString = (day: string) => {
    const previousDay = new Date(day);
    previousDay.setDate(previousDay.getDate() - 1);
    return formatDate(previousDay.toISOString(), FORMAT_DATE_MODE.ISO_8601);
};

export const getNextDayString = (day: string) => {
    const nextDay = new Date(day);
    nextDay.setDate(nextDay.getDate() + 1);
    return formatDate(nextDay.toISOString(), FORMAT_DATE_MODE.ISO_8601);
};

export const isDateValid = (date?: string): boolean => {
    const dayFilters = getDayFilters();
    return dayFilters.some((filter) => filter.value === date);
};

export const isOnAir = (publicationDate: string, endTime?: string): boolean => {
    const start = new Date(publicationDate).getTime();
    const now = endTime ? new Date(endTime).getTime() : Date.now();
    return start <= now;
};
