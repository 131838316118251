import { ROUTES_CONFIG } from './routes';
import { ROUTES } from './types';

export const ROUTES_LIST_SSR = [
    ROUTES.HOME,
    ROUTES.SEARCH,
    ROUTES.CONTACT,
    ROUTES.CONTACT_IOD,
    ROUTES.CONTACT_WITH_US,
    ROUTES.CUSTOMER_CARE_HELP,
    ROUTES.CUSTOMER_CARE_HELP_ARTICLE,
    ROUTES.CUSTOMER_CARE_FULL_ERROR_PAGE,
    ROUTES.CUSTOMER_CARE_FORM_SUCCESS_PAGE,
    ROUTES.CUSTOMER_CARE_ERROR_PAGE,
    ROUTES.MENU_MY_LIST,
    ROUTES.RULES,
    ROUTES.COOKIES_PREFERENCES,
    ROUTES.PRIVACY_POLICY,
    ROUTES.OFFER,
    ROUTES.PACKETS,
    ROUTES.PACKET_DETAILS,
    ROUTES.PACKET_FULL_DETAILS,
    ROUTES.HAVE_CODE,
    ROUTES.VOD,
    ROUTES.VOD_MOVIE_DETAILS,
    ROUTES.VOD_MOVIE_FULL_DETAILS,
    ROUTES.VOD_MOVIE_WATCH,
    ROUTES.VOD_EPISODE_DETAILS,
    ROUTES.VOD_EPISODE_DETAILS_LASTEST,
    ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY,
    ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY_DETAILS,
    ROUTES.VOD_EPISODE_FULL_DETAILS,
    ROUTES.VOD_EPISODE_WATCH,
    ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS,
    ROUTES.VOD_EPISODE_WITH_SEASON_FULL_DETAILS,
    ROUTES.VOD_EPISODE_WITH_SEASON_WATCH,
    ROUTES.VOD_WATCH,
    ROUTES.VOD_SEASON_DETAILS,
    ROUTES.LIVE,
    ROUTES.LIVE_DETAILS,
    ROUTES.LIVE_WATCH,
    ROUTES.LIVE_FULL_DETAILS,
    ROUTES.CHANNELS_TV,
    ROUTES.CHANNEL_TV_DETAILS,
    ROUTES.CHANNEL_TV_FULL_DETAILS,
    ROUTES.CHANNEL_TV_WATCH,
    ROUTES.CHANNEL_TV_EPG,
    ROUTES.CHANNEL_TV_EPG_FULL_DETAILS,
    ROUTES.CATCHUP_WATCH,
    ROUTES.REGISTRATION_QUICK_CHECK_EMAIL,
    ROUTES.REGISTRATION_QUICK_SET_PASSWORD,
    ROUTES.REGISTRATION_QUICK_MAGIC_LINK,
    ROUTES.REGISTRATION,
    ROUTES.REGISTRATION_CONFIGURATION,
    ROUTES.REGISTRATION_ACCOUNT_EXISTS,
    ROUTES.WELCOME,
    ROUTES.LOGIN,
    ROUTES.LOGIN_NATIVE,
    ROUTES.LOGIN_FACEBOOK,
    ROUTES.LOGIN_IPOLSATBOX,
    ROUTES.LOGIN_BY_SMS,
    ROUTES.TV_CODE,
    ROUTES.TV_CODE_LOGGED_USER,
    ROUTES.FACEBOOK_CONNECT,
    ROUTES.FACEBOOK_CONNECT_SUCCESS,
    ROUTES.FACEBOOK_DISCONNECT,
    ROUTES.FACEBOOK_DISCONNECT_SUCCESS,
    ROUTES.IPOLSATBOX_CONNECT,
    ROUTES.IPOLSATBOX_CONNECT_SUCCESS,
    ROUTES.IPOLSATBOX_DISCONNECT,
    ROUTES.IPOLSATBOX_DISCONNECT_SUCCESS,
    ROUTES.LOGIN_RULES,
    ROUTES.LOGIN_RULES_OBLIGATORY,
    ROUTES.LOGIN_RULES,
    ROUTES.RESET_PASSWORD,
    ROUTES.RESET_PASSWORD_SUCCESS,
    ROUTES.RESET_PASSWORD_ERROR,
    ROUTES.RESET_PASSWORD_REQUEST,
    ROUTES.RESET_PASSWORD_CHECK_EMAIL,
    ROUTES.SET_PASSWORD_INFO,
    ROUTES.ACCOUNT_ACTIVATION,
    ROUTES.EMAIL_ACTIVATION,
    ROUTES.EMAIL_ACTIVATION_CHECK_EMAIL,
    ROUTES.ADD_ACCOUNT_EMAIL,
    ROUTES.ADD_ACCOUNT_EMAIL_STATUS,
    ROUTES.PLUS_ZONE_REMOVE_PHONE,
    ROUTES.PLUS_ZONE_REMOVE_PHONE_SUCCESS,
    ROUTES.PLUS_ZONE_ADD_PHONE,
    ROUTES.PURCHASE_PLUS_ZONE_ADD_PHONE,
    ROUTES.FREE_ACCESS_PLUS_ZONE,
    ROUTES.FREE_ACCESS_PLUS_ZONE_ADD_PHONE,
    ROUTES.FREE_ACCESS_PLUS_ZONE_ADD_PHONE_SUCCESS,
    ROUTES.PLUS_ZONE_ADD_PHONE_SUCCESS,
    ROUTES.PURCHASE_PLUS_ZONE_ADD_PHONE_SUCCESS,
    ROUTES.SETTINGS,
    ROUTES.CONSENTS_LIST,
    ROUTES.REGISTER_PAYMENT_CARD,
    ROUTES.REGISTER_PAYMENT_CARD_STATUS,
    ROUTES.PURCHASE,
    ROUTES.PURCHASE_STATUS,
    ROUTES.ORDER_STATUS,
    ROUTES.PAYMENT_CARD_STATUS,
    ROUTES.PURCHASE_OFFER_RULE,
    ROUTES.PURCHASE_PRICING_PLAN_RULE,
    ROUTES.ACCOUNT,
    ROUTES.ACCOUNT_EXISTS,
    ROUTES.ACCOUNT_CHANGE_EMAIL,
    ROUTES.ACCOUNT_CHANGE_EMAIL_SUCCESS,
    ROUTES.ACCOUNT_CHANGE_PASSWORD,
    ROUTES.ACCOUNT_CHANGE_PASSWORD_SUCCESS,
    ROUTES.ACCOUNT_SET_PASSWORD,
    ROUTES.ACCOUNT_SET_PASSWORD_SUCCESS,
    ROUTES.ACCOUNT_CHECK_EMAIL,
    ROUTES.ACCOUNT_DELETE,
    ROUTES.ACCOUNT_DELETE_SUCCESS,
    ROUTES.ACCOUNT_RESTORE_SETTINGS,
    ROUTES.ACCOUNT_HELP,
    ROUTES.ACCOUNT_UNSUBSCRIBE,
    ROUTES.EU_ACCESS,
    ROUTES.EU_VERIFICATION_SUCCESS,
    ROUTES.PROFILE,
    ROUTES.PROFILE_LOGIN_PROCESS,
    ROUTES.PROFILE_ADD,
    ROUTES.PROFILE_ADD_LOGIN_PROCESS,
    ROUTES.PROFILE_WATCH_AS,
    ROUTES.PROFILE_SELECT_TO_EDIT,
    ROUTES.PROFILE_SELECT_TO_EDIT_LOGIN_PROCESS,
    ROUTES.PROFILE_REMOVE,
    ROUTES.PROFILE_REMOVE_DEFAULT,
    ROUTES.PROFILE_REMOVE_DEFAULT_LOGIN_PROCESS,
    ROUTES.PROFILE_REMOVE_SUCCESS,
    ROUTES.PROFILE_PARENT_CONTROL_PASSWORD,
    ROUTES.PROFILE_PARENT_CONTROL_AGE,
    ROUTES.ACCOUNT_MY_PURCHASES,
    ROUTES.NOT_FOUND,
    ROUTES.MY_ACCESS,
    ROUTES.MY_ACCESS_RESIGNATION_CONFIRM,
    ROUTES.MY_ACCESS_RESIGNATION_SUCCESS,
    ROUTES.MY_ACCESS_RENEW_CONFIRM,
    ROUTES.MY_ACCESS_RENEW_SUCCESS,
    ROUTES.MY_PAYMENTS,
    ROUTES.LOGIN_TO_CONTINUE,
    ROUTES.RESOLVE_MEDIA,
    ROUTES.RESOLVE_CATEGORY,
    ROUTES.EVENTS,
    ROUTES.DELETE_PAYMENT_CARD_SUCCESS,
    ROUTES.DELETE_PAYMENT_CARD_CONFIRM,
    ROUTES.TYRZADZISZ,
    ROUTES.POLONIA,
    ROUTES.EMAIL_ACTIVATION_REVERT_CHANGE_EMAIL,
    ROUTES.PACKET_EXTERNAL_ACTIVATION_FAIL,
    ROUTES.MY_ACCESS_DISNEY_ACTIVATION_FAIL,
    ROUTES.EMAIL_SET,
    ROUTES.PROMOTION,
    ROUTES.PROMOTION_STATUS,
    ROUTES.PROMOTION_TAKE_GIFT,
    ROUTES.GOODBYE,
    ROUTES.PARTNERSHIP_MODEL_CHECK_CODE,
    ROUTES.PARTNERSHIP_MODEL_CHECK_CODE_ADD_PHONE,
    ROUTES.PARTNERSHIP_MODEL_REGISTER_CODE,
    ROUTES.PARTNERSHIP_MODEL_REGISTER_CODE_STATUS,
    ROUTES.COLLECTION,
];

export const ROUTES_CONFIG_SSR = ROUTES_CONFIG.filter(({ name }) => ROUTES_LIST_SSR.includes(name));
