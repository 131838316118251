import { KEYS } from './keys';
import buildBrowserKeysList from './browser';
import { buildTizenKeysList, initTizenKeys } from './tizen';
import { WebOSKeysAndAppKeys } from './webos';
import { getNavigatorPlatform } from '@/services/platform-recognition';
import { GM_DEVICE_TYPE, GM_OS } from '@/types/platform.type';
import { buildUpcKeysList } from './upc';
import { buildStbKeysList } from './stb';
import { isStb } from '@/services/stb/getStbConfig';
import { PanasonicKeys } from './panasonic';
import { VIDAAKeys } from './vidaa';
import { WhaleKeys } from './whale';
import { SaphiKeys } from './saphi';
import { buildXboxKeysList } from './xbox';
import { TiVoKeys } from './tivo';

const InputManager = new (class {
    constructor() {
        Object.assign(this.KEY, buildBrowserKeysList());
        getNavigatorPlatform().then((platform) => {
            if (platform.os === GM_OS.Tizen) {
                initTizenKeys();
                const tizenKeys = buildTizenKeysList();
                Object.assign(this.KEY, tizenKeys);
            } else if (platform.os === GM_OS.UPC) {
                const upcKeys = buildUpcKeysList();
                Object.assign(this.KEY, upcKeys);
            } else if (platform.os === GM_OS.WebOS) {
                Object.assign(this.KEY, WebOSKeysAndAppKeys);
            } else if (platform.os === GM_OS.PANASONIC) {
                Object.assign(this.KEY, PanasonicKeys);
            } else if (platform.os === GM_OS.VIDAA) {
                Object.assign(this.KEY, VIDAAKeys);
            } else if (platform.os === GM_OS.WHALE) {
                Object.assign(this.KEY, WhaleKeys());
            } else if (platform.os === GM_OS.SAPHI) {
                Object.assign(this.KEY, SaphiKeys());
            } else if (platform.os === GM_OS.TIVO) {
                Object.assign(this.KEY, TiVoKeys());
            } else if (isStb(platform)) {
                const stbButtons = buildStbKeysList();
                Object.assign(this.KEY, stbButtons);
            } else if (platform.os == GM_OS.Windows && platform.deviceType == GM_DEVICE_TYPE.XBOX) {
                const xboxKeys = buildXboxKeysList();
                Object.assign(this.KEY, xboxKeys);
            }
        });
    }

    public KEY: {
        [key in KEYS]: number;
    } = buildEmptyKeysList();
})();

export { InputManager };

function buildEmptyKeysList(): { [key in KEYS]: number } {
    const result: any = {};
    Object.keys(KEYS).forEach((k) => (result[k] = -1));

    return result as { [key in KEYS]: number };
}
