import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { FreeAccessPlusZone } from '@/components/shared/FreeAccess/FreeAccessPlusZone';
import BaseLayout from '@/components/shared/layouts/BaseLayout';

const FreeAccessPlusZoneComponent = () => {
    return (
        <BaseLayout fullscreen bgShade={SHADE_MODE.CUSTOM} showLogo defaultBg>
            <FreeAccessPlusZone />
        </BaseLayout>
    );
};

export default FreeAccessPlusZoneComponent;
