import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.GET_CATCH_UP_EPG;

const useGetCatchUpEpg = (epgId: string) => {
    const params = {
        epgId: epgId,
    };
    const options = {
        appendAuthData: true,
    };

    const keyHook = defaultKeyHook(namespace, method, params);
    return useGetMediaApi(keyHook, options);
};
export default useGetCatchUpEpg;
