import { useEffect, useMemo, useState } from 'react';
import {
    IPlayerStatus,
    IPlayerTimeMarkers,
    PLAYER_STATE,
    PlayerErrorGroup,
} from '@/interfaces/player.interface';
import { DEFAULT_PLAYER_STATUS, DEFAULT_PLAYER_TIME_MARKERS } from '@/configs/player.config';
import { useRedirectToWatch } from '../use-redirect-to-watch';
import { playerMediaTypeToCpid } from '@/helpers/player.helper';
import { usePlayerOverlays } from './use-player-overlays';

const applyNewState = (
    oldStatus: IPlayerStatus,
    incomingState: IPlayer.PLAYER_STATE,
): Partial<IPlayerStatus> => {
    // omit buffering state here to maintain ad/content and playing/paused states
    // keep buffering as separate flag in status object
    if (incomingState === 'BUFFERING') {
        return {};
    }
    // set error object for NETWORK_ERROR
    if (incomingState === 'NETWORK_ERROR') {
        return {
            state: incomingState,
            error: {
                group: PlayerErrorGroup.NETWORK,
            },
        };
    }
    // clear error object when leaving error state
    const errors: PLAYER_STATE[] = ['NETWORK_ERROR', 'ERROR'];
    if (errors.includes(oldStatus.state) && !errors.includes(incomingState)) {
        return {
            state: incomingState,
            error: undefined,
        };
    }
    return { state: incomingState };
};

export const usePlayerStateManager = (media?: IPlayer.MediaInputData) => {
    const [playerStatus, setPlayerStatus] = useState<IPlayerStatus>(DEFAULT_PLAYER_STATUS);
    const [timeMarkers, setTimeMarkers] = useState<IPlayerTimeMarkers>(DEFAULT_PLAYER_TIME_MARKERS);
    const { setAutoStartTeravolt, setIsTeravoltEnabled } = usePlayerOverlays();
    const { redirectToWatchByMediaId } = useRedirectToWatch();

    useEffect(() => {
        setPlayerStatus((oldStatus) => {
            // Jeśli wystąpi błąd lub stan buforowania i materiał jest wciąż odtwarzany,
            // to ukrywamy planszę błędu i buforowania
            if (oldStatus.error || oldStatus.buffering) {
                return {
                    ...oldStatus,
                    error: undefined,
                    buffering: false,
                };
            }

            return oldStatus;
        });
    }, [timeMarkers.currentTime]);

    const bindings = useMemo(() => {
        const onTimeUpdate = (
            { position, buffers, bandwidth }: IPlayer.TimeUpdateData,
            player: IPlayer.PlayerAPI,
        ) => {
            const range = player.getSkipRange();
            const status = player.getStatus();
            const skipRange = range || { start: 0, end: status.playback.duration };
            const bufferedTime = buffers[buffers.length - 1] ? buffers[buffers.length - 1].end : 0;

            setTimeMarkers((oldStatus) => ({
                ...oldStatus,
                currentTime: Math.round(position),
                bufferedTime,
                skipRange,
                bandwidth,
            }));
        };

        const onCastStatus = (castStatus: IPlayer.CastStatus) => {
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                castStatus,
            }));
        };

        const onCastSessionState = (castSessionState: IPlayer.CAST_SESSION_STATE) => {
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                castSessionState,
            }));
        };

        const onError = (error: IPlayer.CPError) => {
            const {
                isAccessError,
                isDRMSystemUnavailableError,
                isDRMSystemError,
                isAdError,
                isCacError,
                isCacConnectionError,
                isCacDRMExceededError,
                isWebSocketError,
            } = CyfrowyPlayer.CPError;

            let group = PlayerErrorGroup.UNKNOWN;

            if (
                isCacError(error) ||
                isWebSocketError(error) ||
                isCacConnectionError(error) ||
                isCacDRMExceededError(error)
            ) {
                group = PlayerErrorGroup.CONCURRENCY_LIMIT;
            } else if (error.code === 2250) {
                group = PlayerErrorGroup.AD_BLOCKER_DETECTED;
            } else if (isAccessError(error)) {
                group = PlayerErrorGroup.ACCESS;
            } else if (isDRMSystemUnavailableError(error)) {
                group = PlayerErrorGroup.DRM_UNAVAILABLE;
            } else if (isDRMSystemError(error)) {
                group = PlayerErrorGroup.DRM;
            } else if (isAdError(error)) {
                group = PlayerErrorGroup.AD;
                // Tymczasowe wyłączenie planszy błędu reklam - https://jira.polsatc/browse/SWN-1354
                return;
            }
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                error: {
                    group,
                    description: error.htmlInfo,
                    code: error.code,
                    backendInfo: error.backendInfo,
                },
            }));
        };

        const onPlaybackOptionsUpdated = (playbackOptions: IPlayer.PlaybackOptions) => {
            const sortByLabel = (kind: any) =>
                kind.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

            const sortByValue = (kind: any) =>
                kind.sort((a: any, b: any) => (a.value.height > b.value.height ? 1 : -1));

            const sortedOptions = {
                audio: sortByLabel(playbackOptions.audio),
                text: sortByLabel(playbackOptions.text),
                video: sortByValue(playbackOptions.video),
            };
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                playbackOptions: sortedOptions,
            }));
        };

        const onLinearAdStarted = (ad: IPlayer.Ad) =>
            setPlayerStatus((oldStatus) => ({ ...oldStatus, ad }));

        const onLinearAdUpdate = ({ ad, position }: IPlayer.AdTimeData) => {
            const adTimeLeft = Math.round(ad.pod.timeLeft);

            setPlayerStatus((oldStatus) => ({ ...oldStatus, ad }));

            if (adTimeLeft >= 0) {
                setTimeMarkers((oldStatus) => ({ ...oldStatus, adTimeLeft }));
            }
        };

        const onLinearAdPodEnded = () =>
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                ad: undefined,
            }));

        const updateStatus = (player: IPlayer.PlayerAPI) => {
            const incomingStatus = player.getStatus();
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                ...applyNewState(oldStatus, incomingStatus.state),
                phase: incomingStatus.phase,
                playback: {
                    duration: incomingStatus.playback.duration,
                    adBreaks: incomingStatus.playback.adBreaks,
                },
            }));
        };

        const onBuffering = (buffering: boolean) =>
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                buffering,
            }));

        const onStateChange = (incomingState: IPlayer.PLAYER_STATE) =>
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                ...applyNewState(oldStatus, incomingState),
            }));

        const onPhaseChange = (phase: IPlayer.PLAYER_PHASE) =>
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                phase,
            }));

        const onPlayNext = () => {
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                isReadyToPlayNext: false,
            }));
        };

        const onReadyToPlayNext = (isReadyToPlayNext: boolean) => {
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                isReadyToPlayNext,
            }));
        };

        const onSkipZoneStarted = () => {
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                isAllowedToSkipZone: true,
            }));
        };
        const onSkipZoneEnded = () => {
            setPlayerStatus((oldStatus) => ({
                ...oldStatus,
                isAllowedToSkipZone: false,
            }));
        };

        const onPlaybackReady = (playbackDefinition: IPlayer.PlaybackDefinition) => {
            const { teravoltOverlay } = playbackDefinition.media;

            if (teravoltOverlay) {
                setAutoStartTeravolt(teravoltOverlay.autoStart);
                setIsTeravoltEnabled(teravoltOverlay.isEnabled);
            }

            const currentMedia = media?.gmID;
            const playbackMedia = playbackDefinition.media;

            if (currentMedia && playbackMedia) {
                const { id: currentMediaId, cpid: currentMediaCpid } = currentMedia;
                const { id: playbackMediaId, type: playbackMediaType } = playbackMedia;
                const playbackMediaCpid = playerMediaTypeToCpid(playbackMediaType);

                if (currentMediaId === playbackMediaId && currentMediaCpid === playbackMediaCpid) {
                    return;
                }

                redirectToWatchByMediaId(playbackMediaId, playbackMediaCpid);
            }
        };

        return {
            onCastStatus,
            onCastSessionState,
            onBuffering,
            onTimeUpdate,
            onError,
            onPlaybackOptionsUpdated,
            onLinearAdStarted,
            onLinearAdUpdate,
            onLinearAdPodEnded,
            onStateChange,
            onPhaseChange,
            onPlayNext,
            onReadyToPlayNext,
            onSkipZoneStarted,
            onSkipZoneEnded,
            onPlaybackReady,
            updateStatus,
        };
    }, [media]);

    return {
        playerStatus,
        timeMarkers,
        bindings,
    };
};
