import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { BackgroundContainer } from '@/components/shared/BackgroundContainer';
import { RatingIconWrapper } from '@/components/shared/RatingIconWrapper/styles';
import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { fillIcon } from '@/helpers/fill-icon.helper';
import { Z_INDEX } from '@/targets/default/theme';

import {
    IControlsContainerProps,
    IFlexBoxProps,
    IFlexItemProps,
    IMediaSubtitleProps,
    IOverlayContainerProps,
    IPlaybackTimeContainerProps,
} from './types';
import { GUTTER } from '@/types/gutter.type';

export const ControlsContainer = styled.div<IControlsContainerProps>`
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    z-index: ${Z_INDEX.PLAYER_CONTROLS_2};
    transition: ${({ visible }) =>
        CheckSlowDevice(`opacity ${visible ? '0.3' : '3'}s ease-in-out}`)};
`;

export const MediaTitleContainer = styled.div`
    flex: 1;
    min-width: 0;
`;

export const MediaTitleInnerContainer = styled.div`
    display: flex;
    align-items: baseline;
    min-width: 0;
`;

export const MediaTitleIconsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2rem;

    & > *:not(:last-child) {
        margin-right: 0.4rem;
    }
`;

export const MediaTitleTextContainer = styled.h1`
    color: ${({ theme }) => theme.text.color.primary};
    font-size: ${({ theme }) => theme.text.size.pageTitle};
    font-weight: 400;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const MediaLabel = styled.div`
    font-size: ${({ theme }) => theme.text.size.description};
    color: ${({ theme }) => theme.text.color.secondary};
    font-weight: 400;
`;

export const MediaSubtitle = styled.span<IMediaSubtitleProps>`
    font-size: ${({ theme }) => theme.text.size.subtitle};
    color: ${({ theme, secondary }) =>
        secondary ? theme.text.color.secondary : theme.text.color.primary};
    font-weight: 400;
    & > svg {
        height: ${({ theme }) => theme.text.size.subtitle};
        vertical-align: middle;
        fill: ${({ theme, secondary }) =>
            secondary ? theme.text.color.secondary : theme.text.color.primary};
        display: inline;
        width: auto;
        margin-left: 1rem;
    }
`;

export const PlayerRatingIconWrapper = styled(RatingIconWrapper)`
    svg {
        height: 2.2rem;
    }
`;

const AbsoluteContainer = styled.div`
    position: absolute;
    z-index: ${Z_INDEX.PLAYER_CONTROLS_1};
`;

export const TopBarContainer = styled(AbsoluteContainer)`
    width: 100%;
    left: 0;
    top: 0;
    padding: 8rem 11rem 0 11rem;
`;

export const CenterBarContainer = styled(AbsoluteContainer)`
    left: 0;
    top: 22rem;
    bottom: 32rem;
    width: 1px;
`;

export const BottomBackground = styled(AbsoluteContainer)<{ cover: number }>`
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) 100%
    );
    transform: scale(2) translateY(${({ cover }) => ((0.5 - cover) / 2) * 100}%);
    transition: transform 0.2s ease-out;
`;

export const BottomBarContainer = styled(AbsoluteContainer)`
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0;
`;

export const SideBarContainer = styled(AbsoluteContainer)`
    right: 0;
    top: 0;
    padding: 7rem 8rem 6rem 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-image: linear-gradient(-90deg, rgba(0, 0, 0, 1) 60rem, rgba(0, 0, 0, 0) 100%);
`;

export const OverlayContainer = styled(Nav as any)<IOverlayContainerProps>`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    z-index: ${Z_INDEX.PLAYER_CONTROLS_3};
    pointer-events: ${({ passThrough }) => (passThrough ? 'none' : 'auto')};
    > * {
        pointer-events: auto;
    }
`;

interface IBufferingContainerProps {
    visible?: boolean;
}

export const BufferingContainer = styled.div<IBufferingContainerProps>`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity linear 0.3s;
    padding-top: 50rem;
    z-index: ${Z_INDEX.PLAYER_CONTROLS_1};
    pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
    > * {
        pointer-events: auto;
    }
    background-color: rgba(0, 0, 0, 0.8);
`;

export const ErrorContainer = styled(OverlayContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: ${Z_INDEX.PLAYER_CONTROLS_4};
`;

export const ErrorInnerContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 80rem;
`;

export const CacTableHeader = styled.div<{ grayed?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.text.color.secondary};
    font-size: 1.6rem;
`;

export const CacTableRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid;
    border-color: ${({ theme }) => theme.text.color.secondary};
    border-width: 0.16rem 0;
    padding: 3.4rem 0;
`;

export const TableInlineLoader = styled.span`
    max-width: 20rem;
`;

export const NavFlexBox = styled(Nav as any)<IFlexBoxProps>`
    display: flex;
    align-items: center;
    margin-left: ${({ horizontalMargin }) => horizontalMargin};
    margin-right: ${({ horizontalMargin }) => horizontalMargin};
    margin-bottom: ${({ gutter }) => gutter};
`;

export const FlexBox = styled.div<IFlexBoxProps>`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: ${({ horizontalMargin }) => horizontalMargin};
    margin-right: ${({ horizontalMargin }) => horizontalMargin};
    margin-bottom: ${({ gutter }) => gutter};
    visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

export const FlexItem = styled.div<IFlexItemProps>`
    flex-shrink: ${({ shrink }) => (shrink ? 1 : 0)};
    flex-grow: ${({ grow }) => (grow ? 1 : 0)};
    padding: ${({ paddingLeft, paddingRight }) => `0 ${paddingRight ?? 0} 0 ${paddingLeft ?? 0}`};
    overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden')};
`;

export const PlaybackTimeContainer = styled.div<IPlaybackTimeContainerProps>`
    white-space: nowrap;
    font-size: 2.4rem;
    text-align: right;
    width: ${({ fixedWidth }) => (fixedWidth ? '15rem' : 'auto')};
    color: ${({ theme, live }) => (live ? 'red' : theme.text.color.secondary)};
`;

export const GoToLiveButtonContainer = styled(Nav as any)`
    background: rgba(157, 162, 171, 0.12);
    border-radius: 4rem;
    cursor: pointer;
    font-size: 2.4rem;
    padding: 2rem 5.4rem;
    position: relative;

    &:before {
        content: '';
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 0.6rem;
        background-color: #ff0000;
        position: absolute;
        left: 3.4rem;
        top: 2.9rem;
    }

    &.nav-focused {
        background-color: ${({ theme }) => theme.button.activeBgColor};
        color: #000;

        &:before {
            background-color: #000;
        }
    }
`;

export const OptionGroupHeader = styled.div`
    font-size: 3.8rem;
    font-weight: 600;
    padding-left: 2.8rem;
    color: ${({ theme }) => theme.text.color.primary};
    margin-bottom: 3rem;

    :not(:first-child) {
        margin-top: 2.8rem;
    }
`;

export const PlaybackOptionContainer = styled(Nav as any)`
    ${({ theme }) => fillIcon(theme.button.activeBgColor)};
    svg {
        height: 4rem;
        width: 4rem;
    }

    label {
        color: ${({ theme }) => theme.text.color.secondary};
        font-size: 2.8rem;
        line-height: 4rem;
    }

    margin-bottom: 1.2rem;
    width: 54rem;
    border-radius: 4rem;
    padding: 2rem 2rem 2rem 3.2rem;
    display: flex;
    justify-content: space-between;

    &.nav-focused {
        background-color: ${({ theme }) => theme.player.activeOptionBackground};
    }
`;

const ProgressBarContainerStyle = css`
    position: relative;
    height: 1.1rem;
    background-color: ${({ theme }) => theme.player.progressBar.background};
    border-radius: 0.5rem;
`;

export const ProgressBarContainer = styled.div`
    ${ProgressBarContainerStyle}
`;

export const SeekableProgressBarContainer = styled(Nav as any)`
    ${ProgressBarContainerStyle}
`;

export const ProgressBarSeekHandle = styled.div.attrs<{ percentagePosition: number }>(
    ({ percentagePosition }) => ({
        style: {
            left: `calc(${percentagePosition}% - 2.4rem)`,
        },
    }),
)<{ percentagePosition: number }>`
    height: 4.8rem;
    width: 4.8rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.player.progressBar.handle};
    position: absolute;
    top: -1.8rem;
    transform: scale(0);
    ${SeekableProgressBarContainer}.nav-focused & {
        transform: scale(1);
    }
`;

export const ProgressBarFilled = styled.div.attrs<{ percentagePosition: number }>(
    ({ percentagePosition }) => ({
        style: {
            width: `${percentagePosition}%`,
        },
    }),
)<{ percentagePosition: number }>`
    border-radius: 0.5rem;
    height: 100%;
    background-color: ${({ theme }) => theme.player.progressBar.fill};
`;

export const ProgressBarRange = styled.input`
    position: absolute;
    bottom: -2rem;
    left: 0;
    z-index: ${Z_INDEX.PLAYER_CONTROLS_1};
    opacity: 0;
    margin: 0;
    width: 100%;
    height: 5rem;
`;

export const ProgressBarAdBreakIndicator = styled.div<{ percentagePosition: number }>`
    z-index: 2;
    top: 0;
    left: ${({ percentagePosition }) => percentagePosition}%;
    right: 0;
    background-color: ${({ theme }) => theme.player.progressBar.adBreak};
    height: 1.1rem;
    width: 1.1rem;
    position: absolute;
    border-radius: 50%;
`;

export const FullscreenModalButtonsNavContainer = styled(Nav as any)`
    padding: 0 13rem;
`;

export const FullscreenModalBackgroundContainer = styled(BackgroundContainer)`
    width: 70rem;
    height: 0%;
    margin: auto;
    padding-top: 32rem;
`;

export const PlayNextButtonContainer = styled(Nav as any)<{ isBottomBarVisible?: boolean }>`
    position: absolute;
    bottom: 4rem;
    right: 8rem;
    transition: 0.2s ease-out;
    z-index: 10;
    display: flex;

    & > div {
        margin-left: 3rem;
    }

    ${({ isBottomBarVisible }) =>
        isBottomBarVisible ? 'transform: translateY(-10rem)' : 'translateY(0)'};

    button {
        position: relative;
        overflow: hidden;
    }
`;
export const StartPlayFMCContainer = styled(Nav as any)`
    display: flex;
    justify-content: end;
    align-items: end;
    padding-bottom: 4rem;
    padding-right: 12rem;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    right: 8rem;
    top: 4rem;
    z-index: 2;
`;

export const FreeAccessContainer = styled.div<{ topGutter?: GUTTER }>`
    width: 60rem;
    display: inline-block;
    ${({ topGutter }) => topGutter && `margin-top: ${topGutter}rem`}
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ButtonContainer = styled.form<{ topGutter?: GUTTER }>`
    width: 47rem;
    display: inline-block;
`;

export const FreeAccessNav = styled(Nav as any)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
`;

export const SwitchCheckboxWrapper = styled(Nav as any)<{ isActive?: boolean }>`
    display: flex;
    ${({ isActive, theme }) =>
        isActive &&
        ` border: 0.1rem solid rgb(255, 199, 0);
    border-radius: 3.2rem;
    padding: 0.5rem;
    `};
`;

export const Text = styled.div<{}>`
    font-size: 2.6rem;
    line-height: 3.8rem;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    color: #a9aeb4;
`;
