export enum ACCESS_STATUS {
    HAS_ACCESS = 'HAS_ACCESS',
    NO_ACCESS = 'NO_ACCESS',
}

export enum SPECIAL_ACCESS_STATUS {
    ACTION_NEEDED = 'actionNeeded', //do wykonania kacja z callToAction
    GRANTED = 'granted', //masz przyznany dostęp i możesz sobie pooglądać
    DENIED = 'denied', //wykorzystałeś swoją szanse na oglądanie
}
