import { CombinedCell } from './CombinedCell';
import { SingleCell } from './SingleCell';
import { ProgramListProps } from './types';

export const ProgramList = ({
    programs,
    channelName,
    onGoToWatch,
    timeShiftingDuration,
    hasAccess,
}: ProgramListProps) => (
    <>
        {programs.map(([key, value], rowIndex, arr) => {
            const hasMultiplePrograms = value.length > 1;
            const isFirstRow = rowIndex === 0;
            const isLastRow = rowIndex === arr.length - 1;

            const commonProps = {
                channelName,
                onGoToWatch,
                timeShiftingDuration,
            };

            if (hasMultiplePrograms) {
                return (
                    <CombinedCell
                        {...commonProps}
                        key={`${key}-${value[0].program.getId()}`}
                        isFirstRow={isFirstRow}
                        isLastRow={isLastRow}
                        programs={value}
                        hasAccess={hasAccess}
                    />
                );
            }

            return (
                <SingleCell
                    {...commonProps}
                    key={`${key}-${value[0].program.getId()}`}
                    cellsInCell={value.length}
                    height={value[0].height}
                    program={value[0].program}
                    increaseTitleLines
                    hasAccess={hasAccess}
                />
            );
        })}
    </>
);
