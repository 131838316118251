export enum GET_MEDIA_METHODS {
    // Konfiguracja GetMedia
    GET_CONFIGURATION = 'getConfiguration',

    // Lista wszystkich możliwych platform.
    FETCH_PLATFORMS = 'getPlatforms',

    // Lista pozycji menu
    GET_HOME_MENU = 'getHomeMenu',

    // Definicja media obiektu (event, channel, vod, trailer).
    GET_MEDIA = 'getMedia',

    // Lista definicji media obiektów.
    FETCH_MEDIA_LIST = 'getMediaList',

    // Weryfikacja, czy użytkownika ma dostęp do listy media obiektów.
    CHECK_PRODUCTS_ACCESS = 'checkProductsAccess',

    // Weryfikacja czy użytkownika ma dostęp do konkretnego media obiektu.
    CHECK_PRODUCT_ACCESS = 'checkProductAccess',

    // Lista wszystkich eventów jakie są stworzone przez CPZ.
    FETCH_EVENT_LIST = 'getLiveChannels',

    // Lista definicji filtrów dla eventów.
    FETCH_EVENT_CHANNEL_WITH_FLAT_NAVIGATION = 'getLiveChannelsWithTreeNavigation',

    // Lista kanałów
    FETCH_EVENT_TV_CHANNELS = 'getTvChannels',

    // Dane kategorii
    FETCH_CATEGORY = 'getCategory',

    // Lista definicji filtrów dla VOD-ów.
    FETCH_CATEGORY_WITH_FLAT_NAVIGATION = 'getCategoryWithFlatNavigation',

    // Lista materiałów z kategorii z płaską nawigacją.
    FETCH_CATEGORY_CONTENT_WITH_FLAT_NAVIGATION = 'getCategoryContentWithFlatNavigation',

    // Lista materiałów z kategorii.
    FETCH_CATEGORY_CONTENT = 'getCategoryContent',

    //Lista materiałów z kolekcji
    FETCH_STAFF_COLLECTION_CONTENT = 'getStaffCollectionContent',

    //Lista materiałów z kolekcji z płaską nawigacją
    FETCH_STAFF_COLLECTION_CONTENT_FLAT_NAVIGATION = 'getStaffCollectionContentWithFlatNavigation',

    //Lista kategorii z kolekcji z płaską nawigacją
    FETCH_STAFF_COLLECTION_FLAT_NAVIGATION = 'getStaffCollectionWithFlatNavigation',

    // Lista powiązanych vodów
    FETCH_MEDIA_RELATED_CONTENT = 'getMediaRelatedContent',

    // Lista definicji wszystkich kanałów TV.
    FETCH_CHANNEL_LIST = 'getTvChannels',

    // Lista definicji filtrów dla kanałów TV.
    FETCH_CHANNEL_LIST_FLAT_NAVIGATION = 'getTvChannelsFlatNavigation',

    // Lista aktualnych programów we wszystkich kanałach TV.
    FETCH_CHANNEL_LIST_CURRENT_PROGRAM = 'getChannelsCurrentProgram',

    // Lista wszystkich programów we wszystkich kanałach - duży response.
    FETCH_CHANNELS_FULL_PROGRAM = 'getChannelsProgram',

    // Pozycję programu tv na podstawie parametru tvChannelProgramItemId
    FETCH_TV_CHANNEL_PROGRAM_ITEM = 'getTvChannelProgramItem',

    // Subkategorie wskazanej kategorii.
    FETCH_SUBCATEGORIES = 'getSubCategories',

    // Subkategorie wskazanej kategorii z płaską nawigacją.
    FETCH_SUBCATEGORIES_WITH_FLAT_NAVIGATION = 'getSubCategoriesWithFlatNavigation',

    // Pobranie danych wszystkich pakietów
    FETCH_ALL_PACKETS = 'getAllPackets',

    // Lista zakupionych pakietów
    FETCH_POSSESSIONS = 'getPossessions',

    // Dane o subksrybcji pakietu cyklicznego
    FETCH_PRODUCT_SUBSCRIPTIONS = 'getProductSubscriptions',

    // Rezygnacja z subskrypcji cyklicznej
    CANCEL_PRODUCT_SUBSCRIPTION = 'cancelProductSubscription',

    // Wznowienie subskrypcji cyklicznej
    RENEW_PRODUCT_SUBSCRIPTION = 'renewProductSubscription',

    // Pobranie danych kilku wskazanych przez identyfikatory produktów
    FETCH_PRODUCTS = 'getProducts',

    // Pobranie pojedynczego produktu
    FETCH_PRODUCT = 'getProduct',

    // Informacje np. zasad sprzedaży listy produktu
    FETCH_PRE_PURCHASE_DATA = 'getPrePurchaseData',

    // Lista produktów wraz z ich ofertami
    FETCH_MULTIPLE_TYPE_PRODUCTS = 'getMultipleProducts',

    // Lista wszystkich powszechnie dostępnych (darmowych) pakietów
    FETCH_COMMONLY_ACCESSIBLE_PACKETS = 'getCommonlyAccessiblePackets',

    // Logowanie użytkownika.
    LOGIN = 'login',

    // Wylogowanie użytkownika.
    LOGOUT = 'logout',

    // Pobieranie obiektu sesji dla aktualnie zalogowanego użytkownika.
    GET_SESSION = 'getSession',

    // Rejestracja użytkownika.
    REGISTER = 'register',

    // Pobranie kodu do szybkiej rejestracji.
    REQUEST_REGISTER_CODE = 'requestRegisterCode',

    // Weryfikacja kodu podczas szybkiej rejestracji.
    ACCEPT_REGISTER_CODE = 'acceptRegisterCode',

    // Żądanie zmiany hasła dla konta użytkownika.
    REQUEST_PASSWORD_RESET = 'requestPasswordReset',

    // Ustawienie nowego hasła dla konta użytkownika.
    RESET_PASSWORD = 'resetPassword',

    // Odrzucenie rejestracji przez użytkownika, po kliknięciu w link w mailu.
    REJECT_REGISTRATION = 'rejectRegistration',

    // Pobranie niezaakceptowanych regulaminów.
    FETCH_UNACCEPTED_RULES = 'getUnacceptedRules',

    // Pobranie wszystkich regulaminów.
    FETCH_ALL_RULES = 'getAllRules',

    // Pobranie wybranych regulaminów.
    FETCH_RULES = 'getRules',

    // Pobranie stanu akceptacji regulaminów
    FETCH_RULES_STATUS = 'getRulesStatus',

    // Akceptacja regulaminów przez użytkownika.
    SEND_ACCEPTED_RULES = 'acceptRules',

    // Akceptacja regulaminów przez użytkownika.
    SEND_UNACCEPTED_RULES = 'unacceptRules',

    // Aktualizacja profilu użytkownika
    UPDATE_PROFILE = 'updateProfile',

    // Tworzenie profilu użytkownika
    CREATE_PROFILE = 'createProfile',

    // Usuwanie profilu użytkownika
    DELETE_PROFILE = 'deleteProfile',

    // Ustawianie profilu użytkownika w obecnej sesji
    SET_SESSION_PROFILE = 'setSessionProfile',

    // Pobieranie wszystkich profili użytkownika
    GET_PROFILES = 'getProfiles',

    // Zmiana adresu e-mail-a użytkownika
    CHANGE_EMAIL = 'changeEmail',

    // Potwierdzenie zmiany adresu e-mail
    CONFIRM_EMAIL_CHANGE = 'confirmEmailChange',

    // Ustawienie email
    CONFIRM_SET_EMAIL = 'confirmSetEmail',

    // Przywrócenie poprzedniego adresu e-mail
    REVERT_EMAIL_CHANGE = 'revertEmailChange',

    // Zmiana hasła użytkownika
    CHANGE_PASSWORD = 'changePassword',

    // TODO(ksyrytczyk): Jak będzie usługa to trzeba się upewnić że nazwa metody jest poprawna
    // Ustawienie hasła użytkownika
    SET_PASSWORD = 'setPassword',

    // Listy rekomendacyjne.
    // https =//rdm.redefine.pl/projects/getmedia/wiki/Listy_rekomendacji_redakcyjnych
    // https =//rdm.redefine.pl/projects/getmedia/wiki/Dane_list_rekomendacyjnych_dla_danego_miejsca
    FETCH_STAFF_RECOMMENDATIONS_LISTS = 'getStaffRecommendationsLists',
    FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS = 'getStaffRecommendationsListItems',

    /**
     * Dane listy rekomendacyjnej.
     * https =//rdm.redefine.pl/projects/getmedia/wiki/Dane_rekomendacji_redakcyjnych_dla_podanej_listy
     *
     * poniżej są przygotowane mocki dla różnych odpowiedzi z usługi
     * 'getStaffRecommendationsListItems'
     */
    FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS_BLENDER = 'getStaffRecommendationsListItemsBlender',
    FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS_MOVIES = 'getStaffRecommendationsListItemsMovies',
    FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS_VODS = 'getStaffRecommendationsListItemsVods',
    FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS_CATEGORY = 'getStaffRecommendationsListItemsCategory',

    // Wyszukiwarka dla podpowiedzi
    SEARCH_CONTENT = 'searchContent',

    // Wyszukiwarka z podziałem na kategorie
    SEARCH_CONTENT_WITH_TREE_NAVIGATION = 'searchContentWithTreeNavigation',

    FETCH_USER_BUNDLES = 'getUserBundles',
    SET_USER_BUNDLES = 'setUserBundles',

    FETCH_USER_LATELY_WATCHED_CONTENT_DATA_LIST = 'getLatelyWatchedContentDataList',

    PACKET_TREE_NAVIGATION = 'getPacketTreeNavigation',
    PACKET_CONTENT = 'getPacketContent',

    // Zawartość pakietu wraz z kategoriami
    PACKET_CONTENT_WITH_TREE_NAVIGATION = 'getPacketContentWithFlatNavigation',

    // Usunięcie użytkownika
    DELETE_USER = 'deleteUser',
    DELETE_EXTERNAL_USER = 'deleteExternalUser',

    // Weryfikacja użytkownika
    VERIFY_USER = 'verify',

    // Cofnięcie weryfikacji użytkownika
    UNVERIFY_USER = 'unverify',

    // Potwierdzenie weryfikacji użytkownika
    CONFIRM_USER_VERIFICATION = 'confirmVerification',

    // Potwierdzenie rejestracji
    CONFIRM_REGISTRATION = 'confirmRegistration',

    // Generowanie id dla user-a nie zalogowanego
    GET_CLIENT_ID = 'getClientId',

    // Pobieranie kart płatniczych
    GET_PAYMENT_CARDS = 'getPaymentCards',

    // Rejestracja karty płatniczej
    REGISTER_PAYMENT_CARD = 'registerPaymentCard',

    // Rejestracja karty płatniczej
    SET_DEFAULT_PAYMENT_CARD = 'setDefaultPaymentCard',

    // Sprawdzenie statusu rejestracji karty płatniczej
    FETCH_GET_PAYMENT_CARD_REGISTRATION_STATUS = 'getPaymentCardRegistrationStatus',

    // Usuwanie karty płatności
    FETCH_DELETE_PAYMENT_CARD = 'deletePaymentCard',

    // Pobieranie id procesu rejestracji karty płatniczej
    FETCH_GET_PAYMENT_CARD_REGISTRATION_ID = 'getPaymentCardRegistrationId',

    /**
     * Usługa generująca id zamówienia, które jest wymagane do potwierdzenia zakupu oraz dalszego sprawdzania statusu zakupu
     */
    FETCH_ORDER_ID = 'getOrderId',

    // Pobieranie dostępnych avatarów dla profili
    FETCH_AVATARS = 'getAvatars',

    /**
     * Zwraca informacje o pakiecie dla danego kodu.
     */
    GET_PURCHASE_CODE_DATA = 'getPurchaseCodeData',

    GET_PURCHASE_CODE_PRODUCT = 'getPurchaseCodeProduct',

    /**
     * Wysyła żądanie o aktywację kodu.
     * Wymagane jest orderId pobrane za pomocą {@link FETCH_ORDER_ID}
     */
    SUBMIT_PURCHASE_CODE = 'submitPurchaseCode',

    /**
     * Zwraca Id materiału dla aktualnego użytkownika, po podaniu Id kategorii
     */
    GET_CATEGORY_CONTENT_AUTOPLAY_MEDIA_ID = 'getCategoryContentAutoplayMediaId',

    // Pobranie dodatkowych danych opcji zakupu
    FETCH_OPTION_DATA = 'getOptionData',

    // Potwierdzenie zakupu
    FETCH_BUY = 'buy',

    // Sprawdzenie statusu zamówienia
    FETCH_ORDER_STATUS = 'getOrderStatus',

    /**
     * Zwraca informacje o lokalizacji użytkownika (IP, kraj, czy EU)
     */
    GET_IP_DATA = 'getIpData',

    // Sprawdzenie czy w ramach danego produktu możliwe jest uzyskanie triala przy zakupie w ramach oferty cyklicznej
    FETCH_IS_TRIAL_AVAILABLE = 'isTrialAvailable',

    // Lista ulubionych
    GET_FAVORITES = 'getFavorites',

    // Usunięcie elementu z listy ulubionych
    DELETE_FROM_FAVORITES = 'deleteFromFavorites',

    // Dodanie elementu do listy ulubionych
    ADD_TO_FAVORITES = 'addToFavorites',

    // Filtry ulubionych
    GET_FAVORITES_TREE_NAVIGATION = 'getFavoritesTreeNavigation',

    // Lista ulubionych wraz z filtrami do wyboru
    GET_FAVORITES_WITH_FLAT_NAVIGATION = 'getFavoritesWithFlatNavigation',

    // Token dla Activity Events
    GET_ACTIVITY_EVENTS_AUTH_TOKEN = 'getActivityEventsAuthToken',

    // Token dla Player Events
    GET_PLAYER_EVENTS_AUTH_TOKEN = 'getPlayerEventsAuthToken',

    // Pobieranie iformacji o partnerze
    FETCH_PARTNERS = 'getPartners',

    // Wiązanie z kontem natywnym
    CONNECT = 'connect',

    // Odwiązanie od konta natywnego.
    DISCONNECT = 'disconnect',

    // Potwierdzenie wiązania
    CONFIRM_CONNECTION = 'confirmConnection',

    // Usługa zwraca id prośby o logowanie i dane do akceptacji prośby (kod akceptacyjny i url dla qr-kodu).
    REQUEST_LOGIN = 'requestLogin',

    // Status logowania tokenem
    LOGIN_REQUEST_STATUS = 'getLoginRequestStatus',

    // Akceptacja tokenu logowania
    ACCEPT_LOGIN = 'acceptLogin',

    // Usługa zwraca dane oferty oraz powiązane regulaminy i plany cenowe.
    GET_OFFER = 'getOffer',

    // Zmiana oferty na wyższą dla aktywnego zakupionego dostępu
    UPGRAGE_OFFER = 'upgradeOffer',

    // Sprawdzenie czy upgrade oferty się powiódł, czy nie, czy jest w trakcie realizacji
    GET_OFFER_UPGRADE_STATUS = 'getOfferUpgradeStatus',

    // Pobranie informacji związanych z możliwością podwyższenia oferty w ramach aktywnego zakupionego produktu
    GET_PRE_OFFER_UPGRADE_DATA = 'getPreOfferUpgradeData',

    // Sprawdzenie możliwości zewnątrznej aktywacji produkctu (disney+)
    CHECK_PRODUCT_EXTERNAL_ACTIVATION = 'checkProductExternalActivation',

    // Usługa zwraca dane potrzebne do aktywacji posiadanego dostępu do produktu w zew. systemie.
    GET_PRODUCT_EXTERNAL_ACTIVATION_DATA = 'getProductExternalActivationData',

    // Sprawdzenie promocji, w odpowiedzi otrzymujemy statusCode danej promocji która jest przypisana do użytkownika
    CHECK_PROMOTION = 'checkPromotion',

    //  Zużycie kodu promocyjnego i nadanie użytkownikowi odpowiednigo dostępu do produktu.
    CONSUME_PROMOTION = 'consumePromotion',

    // Customer Care- Zwraca listę zawierającą drzewka kategorii.
    GET_CATEGORY_TREE_LIST = 'getCategoryTreeList',

    //Customer Care- Zwraca określony artykuł.
    GET_ARTICLE = 'getArticle',

    //Customer Care-Zwraca listę zawierającą definicję artykułów.
    GET_ARTICLE_LIST = 'getArticleList',

    //Customer Care-Usługa przyjmująca zgłoszenie.
    SUBMIT_HELP_REQUEST = 'submitHelpRequest',

    //pobranie linka z indywidualnym kodem promocyjnym
    GET_USER_PROMOTION_URL = 'getUserPromotionUrl',

    GET_CLIENT_CONTEXT_TOKEN = 'getClientContextToken',

    // Rozpoczyna proces wykorzystania kodu przez użytkownika.
    REGISTER_CODE = 'registerCode',

    //Usługa służy do sprawdzenia stanu kodu jest pierwszą usługą uruchamianą w procesie używania kodu. Sprawdza stan kodu otrzymanego przez użytkownika.
    CHECK_CODE = 'checkCode',

    // służy do odpytywania systemów backendowych o status przetwarzania użytego kodu przez zalogowanego użytkownika
    GET_REGISTER_CODE_STATUS = 'getRegisterCodeStatus',

    // wysyłanie kodu smsem do logowania-model partnerski
    SEND_MSISDN_LOGIN = 'sendMsisdnLogin',

    // pobieranie catchUp dla epg kanalu TV
    GET_CATCH_UP_EPG = 'getCatchUpEntryEpg',
}

export enum GET_MEDIA_NAMESPACES {
    AUTH = 'auth',
    AUTH_2_0 = 'auth/2.0',
    DRM = 'drm',
    NAVIGATION = 'navigation',
    PACKET_BUNDLE = 'packet_bundle',
    PAYMENTS = 'payments',
    SYSTEM = 'system',
    USER_CONTENT = 'user_content',
    HELP = 'help',
    CODE = 'code',
}
