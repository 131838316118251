import { CollectionParams } from '@/components/shared/CollectionsList/types';
import { useGetRecomendationListInitialLimit } from '@/components/shared/CollectionsList/use-get-recommendation-initial-limit.hook';
import { FmcContext } from '@/contexts/fmc/fmc.context';
import { isServer } from '@/helpers/environment.helper';
import {
    buildRecommendationListCollection,
    deviceGuard,
} from '@/helpers/pages/build-recommendation-list-collection.helper';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import useGetStaffRecommendationListsItems from './get-staff-recommendation-lists-items';

export const useGetRecommendationListElements = (
    list: StaffRecommendationListModel | null,
    params: CollectionParams | false,
) => {
    const { followMeContent, isFetching: isFmcFetching } = useContext(FmcContext);

    const initialLimit = useGetRecomendationListInitialLimit(list?.getImageDisplayMode());
    const limit = (params && params.limit) || initialLimit;
    const [offset, setOffset] = useState<number>(0);
    const offerList = list && !list.hasScroll();

    const collectionParams =
        params && list
            ? {
                  place: params.place,
                  staffRecommendationsListId: list.getId(),
                  limit,
                  offset,
              }
            : false;

    const { data, isFetching, error } = useGetStaffRecommendationListsItems(collectionParams);

    const newCollection = useMemo(
        () => buildRecommendationListCollection(list, data, followMeContent),
        [list, data, followMeContent],
    );

    const [collection, setCollection] = useState<ListElementInterface[]>(() =>
        isServer ? newCollection : [],
    );

    const total = data.getTotal();
    const fetchedAll = limit + offset >= total;

    useEffect(() => {
        if (!isFetching && !isFmcFetching) {
            if (newCollection.length) {
                if (offerList) {
                    setCollection(newCollection);
                    return;
                }
                setCollection((prev) => [...prev, ...newCollection]);
            }
        }
    }, [isFetching, isFmcFetching, newCollection, offerList]);

    const handleFetchMore = useCallback(() => {
        const newOffset = limit + offset;

        if (total && newOffset < total) {
            setOffset(newOffset);
        }
    }, [limit, offset, total]);

    const hasData = data.getResults().filter(deviceGuard).length > 0;

    return { collection, isFetching, hasData, total, fetchedAll, handleFetchMore, error };
};
