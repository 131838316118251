import React, { useCallback } from 'react';

import { ICONS } from '@/components/shared/Icon';
import { IconGroup } from '@/components/shared/Metadata';
import { useBuildColumnData } from '@/hooks/epg-table/use-build-column-data.hook';
import { useWalkingEPGData } from '@/hooks/epg-table/use-walking-epg-data.hook';
import { GoToWatchParams } from '@/types/epg-table.type';

import { Cells } from './Cells/Cells';
import * as Styled from './styles';
import { ChannelColumnPresentationProps, ChannelColumnProps } from './types';
import { getDefaultTheme } from '@/helpers/switch-theme.helper';

const ChannelColumnRaw = ({
    channel,
    colIndex,
    hasAccess,
    setActiveColumn,
}: ChannelColumnProps) => {
    const theme = getDefaultTheme();
    const channelId = channel.getId();
    const channelLogo = channel.getTransparentLogoByTheme(theme) || channel.getImage()?.src;
    const channelName = channel.getChannelTitle();
    const timeShiftingDuration = channel.getTimeShiftingDuration();

    const { epgPrograms, isFetching } = useWalkingEPGData(channelId);
    const hasNoData = epgPrograms.length === 0 && !isFetching;
    const { programs } = useBuildColumnData({
        colIndex,
        epgPrograms,
        id: channelId,
        isFetching,
    });

    const handleGoToWatch = useCallback(
        ({ callback, timestamp }: GoToWatchParams) => {
            if (hasAccess) {
                channel.goToWatch(false, timestamp);
            } else {
                callback();
            }
        },
        [hasAccess],
    );

    return (
        <ChannelColumnPresentation
            channelId={channelId}
            channelLogo={channelLogo}
            channelName={channelName}
            colIndex={colIndex}
            hasAccess={hasAccess}
            hasNoData={hasNoData}
            onGoToWatch={handleGoToWatch}
            onNavAction={setActiveColumn}
            programs={programs}
            timeShiftingDuration={timeShiftingDuration}
        />
    );
};

const ChannelColumnPresentationRaw = ({
    channelId,
    channelLogo,
    channelName,
    colIndex,
    hasAccess,
    hasNoData,
    onGoToWatch,
    onNavAction,
    programs,
    timeShiftingDuration,
}: ChannelColumnPresentationProps) => {
    return (
        <Styled.NavColumnWrapper
            id={`channel_column_${colIndex}`}
            onNav={onNavAction(colIndex)}
            restoreLastFocus
        >
            <Styled.ChannelImageWrapper>
                <Styled.ChannelTile src={channelLogo} alt="" />
                {/* SWN-4618-klodki tymczasowo ukryte */}
                {/* {!hasAccess && <IconGroup icon={ICONS.LOCK_CLOSED} iconSize={1.5} />} */}
            </Styled.ChannelImageWrapper>

            {programs && (
                <Cells
                    channelId={channelId}
                    channelName={channelName}
                    colIndex={colIndex}
                    hasNoData={hasNoData}
                    onGoToWatch={onGoToWatch}
                    programs={programs}
                    timeShiftingDuration={timeShiftingDuration}
                    hasAccess={hasAccess}
                />
            )}
        </Styled.NavColumnWrapper>
    );
};

const ChannelColumnPresentation = React.memo(ChannelColumnPresentationRaw);

export const ChannelColumn = React.memo(ChannelColumnRaw);
