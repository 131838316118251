import { ICONS } from '@/components/shared/Icon';
import { ThemeProps } from 'styled-components';
import { ROUTES } from '@/routing/types';
import React, { Dispatch, SetStateAction } from 'react';
import { CyProps } from '@/types/cypress.type';
import { GUTTER } from '@/types/gutter.type';
import { BUTTON_ID } from '@/types/button-id.type';

export enum BUTTON_TYPE {
    PRIMARY = 'primary',
    PRIMARY_LIGHT_FONT = 'primary-light-font',
    SECONDARY = 'secondary',
    PROMOBOX = 'promobox',
    NAV_MENU = 'nav-menu',
    FOOTER_ACTION = 'footer-action',
    PAYMENT_PRIMARY = 'payment-primary',
    PAYMENT_SECONDARY = 'payment-secondary',
    MEDIA_DETAILS_BUY = 'media-details-buy',
    BACK = 'back',
    PRODUCT_CARD_DETAILS = 'product-card-details',
    POLSAT_BOX = 'polsat-box',
    TIMER = 'timer',
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
    APPLE = 'apple',
    START_PLAY_FMC = 'start-play-fmc',
    MAGIC_LINK = 'magic-link',
    CATALOG_NAV = 'catalog-nav',
    DISABLED = 'disabled',
}

export interface ButtonProps extends CyProps {
    route?: ROUTES;
    params?: { [key: string]: any };
    onButtonAction?: () => void;
    text?: string;
    textIcon?: string;
    leftIcon?: ICONS | ICONS[];
    rightIcon?: ICONS | ICONS[];
    active?: boolean;
    transparent?: boolean;
    grayedOut?: boolean;
    fullWidth?: boolean;
    holdActiveStatus?: boolean;
    collapseToIcon?: boolean;
    btnType?: BUTTON_TYPE;
    gutter?: GUTTER;
    overlay?: React.ReactNode;
    noActiveStatus?: boolean;
    className?: string;
}

export interface ButtonStyledProps extends ThemeProps<any> {
    active: boolean;
    holdActiveStatus?: boolean;
    collapseToIcon?: boolean;
    transparent?: boolean;
    grayedOut?: boolean;
    fullWidth?: boolean;
    btnType?: BUTTON_TYPE;
    gutter?: GUTTER;
}

export interface ButtonDisabledWrapperProps<T> {
    children: React.ReactNode;
    button: T;
    setDisabledMessage?: Dispatch<SetStateAction<string>>;
}

export interface CommonButtonProps {
    id?: BUTTON_ID;
    disableTimeout?: number;
    disabledMessage?: string;
}

export interface CustomerCareProps {
    redirectPage?: ROUTES;
    contextType?: string;
    contextValue?: string;
    next: string;
}
