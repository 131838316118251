import { ComponentType } from 'react';

export enum ROUTES {
    ACCOUNT = 'account',
    ACCOUNT_ACTIVATION = 'account-activation',
    ACCOUNT_CHANGE_EMAIL = 'account-change-email',
    ACCOUNT_CHANGE_EMAIL_SUCCESS = 'account-change-email-success',
    ACCOUNT_CHANGE_PASSWORD = 'account-change-password',
    ACCOUNT_CHANGE_PASSWORD_SUCCESS = 'account-change-password-success',
    ACCOUNT_SET_PASSWORD = 'account-set-password',
    ACCOUNT_SET_PASSWORD_SUCCESS = 'account-set-password-success',
    ACCOUNT_CHANGE_PROFILE = 'change-profile',
    ACCOUNT_CHECK_EMAIL = 'account-check-email',
    ACCOUNT_DELETE = 'account-delete',
    ACCOUNT_DELETE_SUCCESS = 'account-delete-success',
    ACCOUNT_EXISTS = 'account-exists',
    ACCOUNT_HELP = 'help',
    ACCOUNT_LOGOUT = 'account-logout',
    ACCOUNT_MY_ACCOUNT = 'my-account',
    ACCOUNT_MY_PURCHASES = 'my-purchases',
    ACCOUNT_RESTORE_SETTINGS = 'account-restore-settings',
    ACCOUNT_UNSUBSCRIBE = 'unsubscribe',
    ADD_ACCOUNT_EMAIL = 'add-account-email',
    ADD_ACCOUNT_EMAIL_STATUS = 'add-account-email-status',
    CHANNELS_TV = 'channels-tv',
    CHANNEL_TV_DETAILS = 'channel-tv-details',
    CHANNEL_TV_FULL_DETAILS = 'channel-tv-full-details',
    CHANNEL_TV_WATCH = 'channel-tv-watch',
    CHANNEL_TV_EPG = 'channel-tv-epg',
    CHANNEL_TV_EPG_FULL_DETAILS = 'channel-tv-epg-full-details',
    CATCHUP_WATCH = 'catch-up-watch',
    CONSENT = 'consent',
    CONSENTS_LIST = 'consents-list',
    CONTACT = 'contact',
    CONTACT_IOD = 'contact-iod',
    CONTACT_WITH_US = 'contact-with-us',
    COOKIE_PREFERENCES = 'cookie-preferences',
    COOKIES_PREFERENCES = 'cookies-preferences',
    DELETE_PAYMENT_CARD_CONFIRM = 'delete-payment-card-confirm',
    DELETE_PAYMENT_CARD_SUCCESS = 'delete-payment-card-success',
    EMAIL_ACTIVATION = 'email-activation',
    EMAIL_ACTIVATION_CHECK_EMAIL = 'email-activation-check-email',
    EMAIL_ACTIVATION_REVERT_CHANGE_EMAIL = 'email-activation-revert-change-email',
    EMAIL_SET = 'email-set',
    EU_ACCESS = 'eu-access',
    EU_VERIFICATION_SUCCESS = 'eu-verification-success',
    ERROR404 = '404',
    EVENTS = 'events',
    EXIT_APP_CONFIRMATION = 'exit-app-confirmation',
    FACEBOOK_CONNECT = 'facebook-connect',
    FACEBOOK_CONNECT_SUCCESS = 'facebook-connect-success',
    FACEBOOK_DISCONNECT = 'facebook-disconnect',
    FACEBOOK_DISCONNECT_SUCCESS = 'facebook-disconnect-success',
    GOODBYE = 'goodbye',
    HAVE_CODE = 'have-code',
    HOME = 'home',
    IPOLSATBOX_CONNECT = 'ipolsatbox-connect',
    IPOLSATBOX_CONNECT_SUCCESS = 'ipolsatbox-connect-success',
    IPOLSATBOX_DISCONNECT = 'ipolsatbox-disconnect',
    IPOLSATBOX_DISCONNECT_SUCCESS = 'ipolsatbox-disconnect-success',
    LIVE = 'live',
    LIVE_DETAILS = 'live-details',
    LIVE_FULL_DETAILS = 'live-full-details',
    LIVE_WATCH = 'live-watch',
    LOGIN = 'login',
    LOGIN_FACEBOOK = 'login-facebook',
    LOGIN_IPOLSATBOX = 'login-ipolsatbox',
    LOGIN_NATIVE = 'login-native',
    LOGIN_BY_CODE = 'login-by-code',
    LOGIN_BY_REMOTE = 'login-by-remote',
    LOGIN_BY_SMS = 'login-by-sms',
    TV_CODE = 'tvcode',
    TV_CODE_LOGGED_USER = 'tvcode-logged-user',
    LOGIN_RULES = 'login-rules',
    LOGIN_RULES_OBLIGATORY = 'login-rules-obligatory',
    MENU_MY_LIST = 'menu-my-list',
    MY_ACCESS = 'my-access',
    MY_ACCESS_DISNEY_ACTIVATION_FAIL = 'my-access-disney-activation-fail',
    MY_ACCESS_RESIGNATION_CONFIRM = 'my-access-resignation-confirm',
    MY_ACCESS_RESIGNATION_SUCCESS = 'my-access-resignation-success',
    MY_ACCESS_RENEW_CONFIRM = 'my-access-renew-confirm',
    MY_ACCESS_RENEW_SUCCESS = 'my-access-renew-success',
    MY_PAYMENTS = 'my-payments',
    NOT_FOUND = 'not-found',
    OFFER = 'offer',
    ORDER_STATUS = 'order-status',
    PACKET = 'packet',
    PACKETS = 'packets',
    PACKET_CONTENT = 'packet-content',
    PACKET_DETAILS = 'packet-details',
    PACKET_EXTERNAL_ACTIVATION_FAIL = 'packet-external-activation-fail',
    PACKET_FULL_DETAILS = 'packet-full-details',
    PAYMENT_CARD_STATUS = 'payment-card-status',
    PLUS_ZONE = 'plus-zone',
    PLUS_ZONE_ADD_PHONE = 'add-phone',
    PURCHASE_PLUS_ZONE_ADD_PHONE = 'purchase-add-phone',
    PLUS_ZONE_ADD_PHONE_SUCCESS = 'add-phone-success',
    PURCHASE_PLUS_ZONE_ADD_PHONE_SUCCESS = 'purchase-add-phone-success',
    PLUS_ZONE_CHANGE_PHONE = 'change-phone',
    PLUS_ZONE_CHANGE_PHONE_SUCCESS = 'change-phone-success',
    PLUS_ZONE_REMOVE_PHONE = 'remove-phone',
    PLUS_ZONE_REMOVE_PHONE_SUCCESS = 'remove-phone-success',
    PRIVACY_POLICY = 'privacy-policy',
    PROFILE = 'profile',
    PROFILE_ADD = 'profile-add',
    PROFILE_ADD_LOGIN_PROCESS = 'profile-add-login-process',
    PROFILE_CHANGE_ICON = 'profile-change-avatar',
    PROFILE_CHANGE_NAME = 'profile-change-name',
    PROFILE_LOGIN_PROCESS = 'profile-login-process',
    PROFILE_PARENT_CONTROL_AGE = 'profile-parent-control-age',
    PROFILE_PARENT_CONTROL_PASSWORD = 'profile-parent-control-password',
    PROFILE_REMOVE = 'profile-remove',
    PROFILE_REMOVE_DEFAULT = 'profile-remove-default',
    PROFILE_REMOVE_DEFAULT_LOGIN_PROCESS = 'profile-remove-default-login-process',
    PROFILE_REMOVE_SUCCESS = 'profile-remove-success',
    PROFILE_SELECT_TO_EDIT = 'profile-select-to-edit',
    PROFILE_SELECT_TO_EDIT_LOGIN_PROCESS = 'profile-select-to-edit-login-process',
    PROFILE_WATCH_AS = 'profile-watch-as',
    PROMOTION = 'promotion',
    PURCHASE = 'purchase',
    PURCHASE_OFFER_RULE = 'purchase-offer-rule',
    PURCHASE_OFFER_RULES = 'purchase-offer-rules',
    PURCHASE_PRICING_PLAN_RULE = 'purchase-princing-plan-rule',
    PURCHASE_PRICING_PLAN_RULES = 'purchase-princing-plan-rules',
    PURCHASE_STATUS = 'purchase-status',
    REGISTER_PAYMENT_CARD = 'register-payment-card',
    REGISTER_PAYMENT_CARD_STATUS = 'register-payment-card-status',
    REGISTRATION_QUICK_CHECK_EMAIL = 'registration-quick/check-email',
    REGISTRATION_QUICK_SET_PASSWORD = 'registration-quick/set-password',
    REGISTRATION_QUICK_MAGIC_LINK = 'registration-quick-magic-link',
    REGISTRATION = 'registration',
    REGISTRATION_ACCOUNT_EXISTS = 'registration-account-exists',
    REGISTRATION_CONFIGURATION = 'registration-configuration',
    RESET_PASSWORD = 'reset-password',
    RESET_PASSWORD_CHECK_EMAIL = 'reset-password-check-email',
    RESET_PASSWORD_ERROR = 'reset-password-error',
    RESET_PASSWORD_REQUEST = 'reset-password-request',
    RESET_PASSWORD_SUCCESS = 'reset-password-success',
    RESOLVE_CATEGORY = 'resolve-category',
    RESOLVE_MEDIA = 'resolve-media',
    RULES = 'rules',
    SEARCH = 'search',
    SETTINGS = 'settings',
    SET_PASSWORD = 'set-password',
    SET_PASSWORD_INFO = 'set-password-info',
    SUBCATEGORY = 'subcategory',
    TYRZADZISZ = 'tyrzadzisz',
    POLONIA = 'polonia',
    VOD = 'video',
    VOD_EPISODE_CATEGORY_AUTOPLAY = 'vod-episode-category-autoplay',
    VOD_EPISODE_CATEGORY_AUTOPLAY_DETAILS = 'vod-episode-category-autoplay-details',
    VOD_EPISODE_DETAILS = 'vod-episode-details',
    VOD_EPISODE_DETAILS_LASTEST = 'lastest-vod-episode-details',
    VOD_EPISODE_FULL_DETAILS = 'vod-episode-full-details',
    VOD_EPISODE_WATCH = 'vod-episode-watch',
    VOD_EPISODE_WITH_SEASON_DETAILS = 'vod-episode-with-season-details',
    VOD_EPISODE_WITH_SEASON_FULL_DETAILS = 'vod-episode-with-season-full-details',
    VOD_EPISODE_WITH_SEASON_WATCH = 'vod-episode-with-season-watch',
    VOD_MOVIE_DETAILS = 'vod-movie-details',
    VOD_MOVIE_FULL_DETAILS = 'vod-movie-full-details',
    VOD_MOVIE_WATCH = 'vod-movie-watch',
    VOD_WATCH = 'vod-watch',
    VOD_SEASON_DETAILS = 'vod-season-details',
    LOGIN_TO_CONTINUE = 'login-to-continue',
    WELCOME = 'welcome',
    PROMOTION_STATUS = 'promotion-status',
    PROMOTION_TAKE_GIFT = 'promotion-take-gift',
    CUSTOMER_CARE_HELP = 'customer-care-help',
    CUSTOMER_CARE_HELP_ARTICLE = 'article-page',
    CUSTOMER_CARE_FORM_SUCCESS_PAGE = 'customer-care-form-success-page',
    CUSTOMER_CARE_FULL_ERROR_PAGE = 'customer-care-full-error-page',
    CUSTOMER_CARE_ERROR_PAGE = 'customer-care-error-page',
    PARTNERSHIP_MODEL_CHECK_CODE = 'partnership-check-code',
    PARTNERSHIP_MODEL_CHECK_CODE_ADD_PHONE = 'partnership-check-code-add-phone',
    PARTNERSHIP_MODEL_REGISTER_CODE = 'partnership-register-code',
    PARTNERSHIP_MODEL_REGISTER_CODE_STATUS = 'partnership-register-code-status',
    COLLECTION = 'collection',
    FREE_ACCESS_PLUS_ZONE = 'free-access-plus-zone',
    FREE_ACCESS_PLUS_ZONE_ADD_PHONE = 'free-access-add-phone',
    FREE_ACCESS_PLUS_ZONE_ADD_PHONE_SUCCESS = 'free-access-add-phone-success',
}

export enum PATHS {
    REGISTRATION_QUICK_CHECK_EMAIL = 'registration-quick/check-email',
    REGISTRATION_QUICK_SET_PASSWORD = 'registration-quick/set-password',
    REGISTRATION_QUICK_MAGIC_LINK = 'registration-quick-magic-link',
    PROMOTION = 'magic-link/promotion',
    PROMOTION_STATUS = 'magic-link/promotion-status',
    PROMOTION_TAKE_GIFT = 'magic-link/promotion-take-gift',
    ADD_ACCOUNT_EMAIL = 'add-account-email/request',
    EMAIL_ACTIVATION = 'email-activation',
    LOGIN = 'login',
    LOGIN_FACEBOOK = 'login/facebook',
    LOGIN_IPOLSATBOX = 'login/ipolsatbox',
    LOGIN_NATIVE = 'login/native',
    LOGIN_BY_CODE = 'login/by-code',
    LOGIN_BY_REMOTE = 'login/by-remote',
    LOGIN_BY_SMS = 'login/by-sms',
    TV_CODE = 'tvcode',
    TV_CODE_LOGGED_USER = 'account/tvcode-logged-user',
    LOGIN_RULES = 'login/rules',
    LOGIN_RULES_OBLIGATORY = 'login/rules-obligatory',
    PROFILE = 'profile',
    PROFILE_ADD = 'profile/add-profile',
    PROFILE_ADD_LOGIN_PROCESS = 'profile/add-profile-login-process',
    PROFILE_CHANGE_ICON = 'profile/change-icon',
    PROFILE_CHANGE_NAME = 'profile/change-name',
    PROFILE_LOGIN_PROCESS = 'profile/login-process',
    PROFILE_REMOVE = 'profile/remove',
    PROFILE_REMOVE_DEFAULT_LOGIN_PROCESS = 'profile/remove-default-login-process',
    PROFILE_REMOVE_SUCCESS = 'profile/remove-success',
    PROFILE_SELECT_TO_EDIT = 'profile/select-to-edit',
    PROFILE_SELECT_TO_EDIT_LOGIN_PROCESS = 'profile/select-to-edit-login-process',
    PROFILE_WATCH_AS = 'profile/watch-as',
    REGISTRATION = 'registration',
    REGISTRATION_CONFIGURATION = 'registration/configuration',
    UNSUBSCRIBE = 'unsubscribe',
    WELCOME = 'welcome',
    CONTACT = 'contact',
    CUSTOMER_CARE_HELP = 'customer-care/help',
    CUSTOMER_CARE_HELP_ARTICLE = 'customer-care/article-page',
    CUSTOMER_CARE_FORM_SUCCESS_PAGE = 'customer-care/form-success-page',
    CUSTOMER_CARE_FULL_ERROR_PAGE = 'customer-care/full-error-page',
    CUSTOMER_CARE_ERROR_PAGE = 'customer-care/error-page',
    PACKET_DETAILS = 'packets/details',
    PARTNERSHIP_MODEL_CHECK_CODE = 'partnership-model/check-code',
    PARTNERSHIP_MODEL_CHECK_CODE_ADD_PHONE = 'partnership-model/add-phone',
    PARTNERSHIP_MODEL_REGISTER_CODE = 'partnership-model/register-code',
    PARTNERSHIP_MODEL_REGISTER_CODE_STATUS = 'partnership-model/register-code-status',
}

export type RouteParams = {
    [k: string]: string | number | boolean;
};

export interface RouteInterface {
    route: ROUTES;
    params?: RouteParams;
    query?: RouteParams;
}

export enum ZONES {
    ACCOUNT = 'account',
    AUTH = 'auth',
    CONTENT = 'content',
    NOT_FOUND = 'not-found',
    LOGIN_TO_CONTINUE = 'login-to-continue',
    LOGIN_WITHOUT_PROFILE = 'login-without-profile',
    CUSTOMER_CARE = 'customer-care',
}

export interface RouteItemConfigInterface {
    name: ROUTES;
    pattern: string;
    page: string;
    zone?: ZONES;
}

export type RoutesConfigInterface = RouteItemConfigInterface[];

export type ConditionalRedirectParamInterface = {
    url?: string;
    routeName?: ROUTES;
    isLogged: boolean;
    redirectRouteZones: ZONES[];
    routes: RoutesConfigInterface;
};

export type IParams = Record<string, any>;

// Parametry, które wpływają na nawigację
// next - kieruje na wskazany w parametrze adres
// target - wskazuje na adres docelowy (np po przejściu logowania)
export type NavigationParams = {
    next?: string;
    target?: string;
};

// Ten interfejs musi być obsłużony przez next i standalone routing
export interface IRouter {
    asPath: string;
    route: string;
    query: IParams;
    events?: any;
    prevRoute?: ROUTES;
    stack?: ROUTES[];
}

// Ten interfejs musi być obsłużony przez next i standalone routing
export interface IInteractiveRouter {
    back: () => void;
    reload: () => void;
    pushRoute: (route: ROUTES, params?: IParams, queryParams?: IParams) => void;
    replaceRoute: (route: ROUTES, params?: IParams, queryParams?: IParams) => void;
}

// Ten interfejs musi być obsłużony przez next i standalone routing
export type ILink = ComponentType<{
    children?: JSX.Element | string;
    route: string;
    params?: IParams;
    passHref?: boolean;
}>;
