import { navVertical } from 'nav-tree';

import { GUTTER } from '@/types/gutter.type';

import t from '@/lib/i18n';
import { Router } from '@/routing';
import { PlayableButton } from '@/components/shared/Buttons';
import { Message } from '@/components/shared/Message';
import {
    ButtonContainer,
    FreeAccessContainer,
    FreeAccessNav,
    SwitchCheckboxWrapper,
    Text,
} from './styles';
import { SwitchCheckbox } from '@/components/shared/SwitchCheckbox';
import { useCallback, useState } from 'react';
import { useLocalStorage } from '@/hooks/use-local-storage.hook';
import { LOCAL_STORAGE_KEYS } from '@/types/storage.type';
import { FreeAccessConfirmationProps } from './types';

export const FreeAccessConfirmation = ({ text, actionBeforePlay }: FreeAccessConfirmationProps) => {
    const [active, setActive] = useState(false);
    const [freeAccessDontAskAgain, setFreeAccessDontAskAgain] = useLocalStorage(
        LOCAL_STORAGE_KEYS.FREE_ACCESS_DONT_ASK_AGAIN,
    );
    const [value, setValue] = useState<boolean>(() => {
        return freeAccessDontAskAgain ? freeAccessDontAskAgain : false;
    });
    const handleToggle = useCallback(() => {
        setValue((prev) => !prev);
        setFreeAccessDontAskAgain((prev) => !prev);
    }, []);

    return (
        <FreeAccessNav func={navVertical}>
            <FreeAccessContainer>
                <Message
                    title={text}
                    description={t('free-access.free-access-confirmation.description')}
                    gutter={GUTTER.NORMAL}
                />
                <ButtonContainer>
                    <PlayableButton
                        text={t('yes-button-label')}
                        onButtonAction={actionBeforePlay}
                        defaultFocused
                        gutter={GUTTER.MEDIUM}
                    />
                    <PlayableButton
                        text={t('cancel-button-label')}
                        onButtonAction={() => Router.back()}
                        gutter={GUTTER.MEDIUM}
                    />
                    {/* ts-ignore */}
                    <SwitchCheckboxWrapper
                        onNav={setActive}
                        isActive={active}
                        onClick={handleToggle}
                        onEnterDown={handleToggle}
                    >
                        <SwitchCheckbox id="saveValue" checked={value} />
                        <Text>{t('free-access.free-access-confirmation.dont-ask-again')}</Text>
                    </SwitchCheckboxWrapper>
                </ButtonContainer>
            </FreeAccessContainer>
        </FreeAccessNav>
    );
};
