import { createContext, useState } from 'react';
import { TeravoltContextInterface, TeravoltContextProviderProps } from './types';

export const TeravoltContext = createContext<TeravoltContextInterface>({
    isTeravoltReady: false,
    setIsTeravoltReady: () => null,
    isTeravoltVisible: false,
    setIsTeravoltVisible: () => null,
});

export const TeravoltContextProvider = ({ children }: TeravoltContextProviderProps) => {
    const [isTeravoltReady, setIsTeravoltReady] = useState(false);
    const [isTeravoltVisible, setIsTeravoltVisible] = useState(false);

    return (
        <TeravoltContext.Provider
            value={{
                isTeravoltReady,
                setIsTeravoltReady,
                isTeravoltVisible,
                setIsTeravoltVisible,
            }}
        >
            {children}
        </TeravoltContext.Provider>
    );
};
