import { VODControls } from './controls';
import { IVODPlayerProps } from './types';
import { PlayerBase } from './PlayerBase';
import { CPID } from '@/types/media.type';
import { useEffect, useMemo, useState } from 'react';
import { useBuildVodData } from '@/hooks/media/use-build-vod-data.hook';
import { StartPlayFMCButtons } from './controls/shared/StartPlayFMCButtons';
import { useIdle } from '@/hooks/use-idle.hook';

const {
    player: {
        transition: { visibilityDelay },
    },
} = process.env;

export const CatchUpPlayer = ({ mediaId, mediaDetails }: IVODPlayerProps) => {
    const [isFmcScreenVisible, setIsFmcScreenVisible] = useState(false);
    const [isWatchingBegin, setIsWatchingBegin] = useState(false);
    const { fmc } = useBuildVodData();
    const idle = useIdle(visibilityDelay);

    useEffect(() => {
        const findFmcId = fmc.find((el) => el.mediaId.id === mediaId);
        setIsFmcScreenVisible(!!findFmcId);
    }, [fmc, mediaId]);

    const load = useMemo(
        () => ({
            media: {
                gmID: {
                    cpid: CPID.CATCH_UPS,
                    id: mediaId,
                },
            },
        }),
        [mediaId],
    );

    const actionKeepWatching = () => {
        setIsFmcScreenVisible(false);
    };

    const actionBeginWatching = () => {
        setIsFmcScreenVisible(false);
        setIsWatchingBegin(true);
    };

    if (isFmcScreenVisible) {
        return (
            <StartPlayFMCButtons
                actionBeginWatching={actionBeginWatching}
                actionKeepWatching={actionKeepWatching}
                isVisible={!idle}
            />
        );
    }

    return (
        <PlayerBase
            load={load}
            mediaDetails={mediaDetails}
            isWatchingBegin={isWatchingBegin}
            isFmcScreenVisible={isFmcScreenVisible}
        >
            <VODControls />
        </PlayerBase>
    );
};
