import { ThemeProps } from 'styled-components';
import { CyProps } from '@/types/cypress.type';

export interface ButtonProps extends ButtonGeneralProps {
    className?: string;
    children?: React.ReactNode;
}

export type ButtonStyledProps = ButtonGeneralProps & ThemeProps<any>;

interface ButtonGeneralProps extends CyProps {
    active?: boolean;
    btnType?: BUTTON_TYPE;
    nativeType?: NATIVE_BUTTON_TYPE;
    fullWidth?: boolean;
    disabled?: boolean;
}

export enum BUTTON_TYPE {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TIMER = 'timer',
}

export enum NATIVE_BUTTON_TYPE {
    BUTTON = 'button',
    RESET = 'reset',
    SUBMIT = 'submit',
}
