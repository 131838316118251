import React from 'react';
import { PlayableButton } from '../../../shared/Buttons';
import {
    ButtonContainer,
    NavButtons,
    ButtonsWrapper,
    BuyChannelTextWrapper,
    BuyChannelText,
} from './styles';
import { IFutureEPGButtons } from './types';
import { MediaButtonInterface } from '@/interfaces/media-button.interface';
import { BUTTON_ID } from '@/types/button-id.type';
import { InfoText, InfoTextContainer } from '../styles';
import t from '@/lib/i18n';

const FutureEPGButtonsRaw = ({
    buttons,
    focusRef,
    onRightPanel,
    onButtonAction,
    startLiveInfo,
    media,
    catchUpPlayButton,
}: IFutureEPGButtons) => {
    const playButton = buttons.find((button) => button.id === BUTTON_ID.PLAY);
    const buyButton = buttons.find((button) => button.id === BUTTON_ID.BASKET);
    const isStartLiveInfoVisible = startLiveInfo?.isStartLiveInfoVisible;
    const mediaListTitle = media?.mediaListTitle;

    const MediaButton = ({ button, index }: { button: MediaButtonInterface; index: number }) => (
        <ButtonContainer>
            <PlayableButton
                navId={button.id}
                onNav={onRightPanel}
                icon={button.icon}
                text={button.text}
                onButtonAction={() => onButtonAction(button, index)}
                defaultFocused={index === 0}
                data-testing={button['data-testing']}
            />
        </ButtonContainer>
    );
    const startLiveInfoText = (
        <>
            <InfoText>{t('start-transmission')}: </InfoText>
            <InfoText highlight>{startLiveInfo.startLiveInfoText}</InfoText>
        </>
    );
    const startLiveInfoMsg = isStartLiveInfoVisible && startLiveInfo?.startLiveInfoText && (
        <InfoTextContainer>{startLiveInfoText}</InfoTextContainer>
    );

    const mediaListTitleText = (
        <>
            <InfoText>{t('components.tv-program-item.buy-channel-text')} </InfoText>
            <InfoText highlight>{mediaListTitle}</InfoText>
        </>
    );

    const filteredButtons = buttons.map((button, index) => {
        const isPlayBtn = button.id === BUTTON_ID.PLAY;
        const isBuyBtn = button.id === BUTTON_ID.BASKET;
        const isInfoBtn = button.id === BUTTON_ID.INFO;

        if ((isPlayBtn || isBuyBtn) && isStartLiveInfoVisible) {
            return null;
        }

        if (catchUpPlayButton && isPlayBtn) {
            return (
                <MediaButton key={catchUpPlayButton.id} button={catchUpPlayButton} index={index} />
            );
        }

        if (isInfoBtn) {
            button.onButtonAction = () => media.goToFullDetails();
        }

        return <MediaButton key={button.id} button={button} index={index} />;
    });
    return (
        <NavButtons ref={focusRef} restoreLastFocus>
            {startLiveInfoMsg}
            {!!buyButton && !isStartLiveInfoVisible && !media.hasAccess() && (
                <BuyChannelTextWrapper>
                    <BuyChannelText>{mediaListTitleText}</BuyChannelText>
                </BuyChannelTextWrapper>
            )}
            {filteredButtons}
            {isStartLiveInfoVisible && (
                <>
                    {buyButton && (
                        <BuyChannelTextWrapper>
                            <InfoTextContainer>{mediaListTitleText}</InfoTextContainer>
                        </BuyChannelTextWrapper>
                    )}
                    <ButtonsWrapper>
                        {playButton && <MediaButton button={playButton} index={0} />}
                        {buyButton && <MediaButton button={buyButton} index={0} />}
                    </ButtonsWrapper>
                </>
            )}
        </NavButtons>
    );
};

export const FutureEPGButtons = React.memo(FutureEPGButtonsRaw);
