// @ts-nocheck
// TODO: Add typescript support

const getSpecialKeys = () => {
    try {
        return {
            PLAY_PAUSE: VK_PLAY_PAUSE,
        };
    } catch (e) {
        return {};
    }
};

export const TiVoKeys = getSpecialKeys;
