import useCheckProductAccess from '@/api/drm/use-check-product-access';
import useGetMedia from '@/api/navigation/use-get-media';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { CPID } from '@/types/media.type';
import { useMemo } from 'react';
import { useRouter } from '@/routing';
import { CatchUpPlayer } from '@/components/shared/Player/CatchUpPlayer';

const ChannelsWatch = () => {
    const { query } = useRouter();
    const { catchUpId } = query;

    const { data: mediaListItem } = useGetMedia({
        mediaId: catchUpId,
        cpid: CPID.CATCH_UPS,
    });
    const product = mediaListItem?.getProduct();
    const { data: productAccess } = useCheckProductAccess(product ? { product } : undefined);
    const mediaDetails = useMemo(
        () => new MediaDetailsModel(mediaListItem, productAccess, undefined),
        [mediaListItem, productAccess],
    );

    return (
        <BaseLayout fullscreen>
            <CatchUpPlayer mediaId={catchUpId} mediaDetails={mediaDetails} />
        </BaseLayout>
    );
};

export default ChannelsWatch;
