import UserModule from '@/modules/user/user.module';
import { getDeviceCTS, getDeviceNUID, getStbIrdetoSerialNumber } from '@/services/stb/getStbConfig';
import { Platform } from '@/services/platform-recognition';
import { getUserAgentData } from '@/services/user-agent-data.service';
import { GM_DEVICE_TYPE } from '@/types/platform.type';
import { IPlayerStatus, PLAYER_MEDIA_TYPE, PLAYER_STATE } from '@/interfaces/player.interface';
import { isStbTargets } from '@/constants/portal-recognition';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';

export const createPlugins = (platform: Platform, userModule: UserModule) => {
    const userRecognition = userModule.getUserRecognition();
    const deviceId = userRecognition.getDeviceId();
    const deviceName = userRecognition.getDeviceName();

    const userAgentData = getUserAgentData(platform);

    const plugins: IPlayer.Plugin[] = [];

    plugins.push({
        canDo: (task) => task.name === 'getDeviceID' && Boolean(deviceId),
        run: async () => deviceId,
    });

    plugins.push({
        canDo: (task) => task.name === 'getDeviceName',
        run: async () => deviceName,
    });

    if (platform.deviceType === GM_DEVICE_TYPE.TV_Hisense) {
        plugins.push({
            canDo: (task) => task.name === 'getPlatformFeatures',
            run: async () => ({
                os: userAgentData.osInfo,
                tags: ['widevine'],
            }),
        });
    }

    if (isStbTargets) {
        const getIrdetoSerialNumber = async () => {
            const irdetoCAID = await getStbIrdetoSerialNumber();
            return irdetoCAID;
        };

        switch (platform.deviceType) {
            case GM_DEVICE_TYPE.STB_D30_EVOBOX_PVR:
                plugins.push({
                    canDo: (task) => task.name === 'getNagraConfig',
                    run: async () => {
                        const NUID = await getDeviceNUID();
                        const CTS = await getDeviceCTS();
                        if (NUID && CTS) {
                            return {
                                NUID,
                                CTS,
                            };
                        }
                    },
                });
                break;
            default:
                plugins.push({
                    canDo: (task) => task.name === 'getIrdetoSerialNumber',
                    run: async () => await getIrdetoSerialNumber(),
                });
                break;
        }
    }

    return plugins;
};

export const getFullscreenState = () => {
    return Boolean(document.fullscreenElement);
};

export const isAdsVisible = (state: PLAYER_STATE) => {
    return state === 'PLAYING_LINEAR_AD' || state === 'PAUSED_LINEAR_AD';
};

export const calculateSeekTimeByPercentage = (
    percentageValue: number,
    start: number,
    end: number,
) => {
    return start + ((end - start) * percentageValue) / 100;
};

export const findEpgStartProgramTimeByCurrentTime = (
    programs: ChannelProgramTvModel[],
    currentTimeMs: number,
): number => {
    let currentEpgProgramTime = 0;

    programs.map((program) => {
        const startProgramTime = program.getStartTimeTimestamp();

        if (startProgramTime > currentTimeMs) {
            return;
        }

        if (!currentEpgProgramTime) {
            currentEpgProgramTime = startProgramTime;
            return;
        }

        const delta = currentTimeMs - startProgramTime;

        if (delta < currentEpgProgramTime) {
            currentEpgProgramTime = startProgramTime;
        }
    });

    return currentEpgProgramTime;
};

export const playerMediaTypeToCpid = (playerMediaType: PLAYER_MEDIA_TYPE) => {
    return playerMediaType === PLAYER_MEDIA_TYPE.LIVE ? 0 : 1;
};
