import { LoaderContext } from '@/contexts/loader/loader.context';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import React, { useContext, useMemo, useState } from 'react';
import { Nav } from 'nav-tree';
import {
    CtaButtonWrapper,
    ElementInnerWrapper,
    ElementWrapper,
    IconWrapper,
    ImageContainer,
    ImageWrapperOfferList,
    DataWrapper,
} from './styles';
import { OfferListElementOwnProps } from './types';
import { Image } from '../Image';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { convertPxToRem } from '@/helpers/convert-px-to-rem.helper';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { Icon, ICONS } from '@/components/shared/Icon';
import { ShadeStyled } from '../BackgroundImage/styles';
import { SHADE_MODE } from '../BackgroundImage';
import { useConvertDeeplinkToUrl } from '@/hooks/use-convert-deeplink-to-url.hook';
import { Router } from '@/routing';

const OfferListElement = ({
    itemData,
    defaultFocused,
    setActiveElement,
    focusedRow,
}: OfferListElementOwnProps) => {
    const [active, setActive] = useState(false);
    const { setShowLoader } = useContext(LoaderContext);
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);

    const image = useMemo(() => itemData.getImage(), [itemData]);

    const imageSize = process.env.imageSize.posters;

    const heightInRem = useMemo(() => convertPxToRem(imageSize.height), [imageSize]);
    const widthInRem = useMemo(() => convertPxToRem(imageSize.width), [imageSize]);
    const onItemNav = (isActive: boolean) => {
        batchedUpdates(() => {
            setShowLoader(false);
            if (isActive) {
                setActiveElement?.(itemData, 0);
            }
            setActive(isActive);
        });
    };
    const { route, params, queryParams } = useConvertDeeplinkToUrl(itemData.getCtaButtonUrlTV());

    const onItemClicked = () => {
        return Router.pushRoute(route, params, queryParams);
    };

    return useMemo(
        () => (
            // @ts-ignore
            <Nav
                onNav={onItemNav}
                onEnterDown={onItemClicked}
                onClick={onItemClicked}
                defaultFocused={defaultFocused}
                autofocusOn={focusedRow && isMagicMouseVisible ? ['mouseenter'] : undefined}
            >
                <ElementWrapper>
                    <ElementInnerWrapper active={active} $width={widthInRem}>
                        <ImageContainer $height={heightInRem} allowFocus={focusedRow}>
                            <ImageWrapperOfferList>
                                {image.src && (
                                    <Image
                                        src={image.src}
                                        width={'100%'}
                                        height={'100%'}
                                        lazy={true}
                                        withPlaceholder={IMAGE_DISPLAY_MODE.POSTERS}
                                    />
                                )}
                                <ShadeStyled
                                    mode={
                                        itemData.isDefaultBackground()
                                            ? SHADE_MODE.NONE
                                            : SHADE_MODE.OVERLAY
                                    }
                                />
                            </ImageWrapperOfferList>
                            <DataWrapper>
                                <IconWrapper isActive={active}>
                                    <Icon name={ICONS.ARROW_RIGHT_CIRCLE} />
                                </IconWrapper>
                                <CtaButtonWrapper isActive={active}>
                                    {itemData.getCtaButtonLabel()}
                                </CtaButtonWrapper>
                            </DataWrapper>
                        </ImageContainer>
                    </ElementInnerWrapper>
                </ElementWrapper>
            </Nav>
        ),
        [active, onItemNav, focusedRow],
    );
};

export const OfferListElementMemo = React.memo(OfferListElement);
